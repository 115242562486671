import React from "react";
import {Popover, Whisper} from "rsuite";

interface ToggleProps {
    className?: string;
    isActive: boolean;
    onToggle: () => Promise<void>;
}

const Toggle: React.FC<ToggleProps> = ({ className, isActive, onToggle }) => {
    return (
        <Whisper placement={"left"} speaker={<Popover>Создать клиента в 1С. Даёт возможность клиенту оформлять заказы</Popover>}>
        <div className={`${className} toggle-switch ${isActive ? 'active' : ''}`} onClick={onToggle}>
            <div className="toggle-knob"></div>
        </div>
        </Whisper>
    );
};

export default Toggle;
