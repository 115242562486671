import React from "react";
import config from "../util/Config";
import {SubCategoryModel} from "../../models/SubCategoryModel";
import {BrandModel} from "../../models/BrandModel";

interface ActiveFiltersProps {
    searchQuery: string;
    setSearchQuery: (value: string) => void;
    setInStock: (value: boolean) => void;
    setSelectedSubCategories: React.Dispatch<React.SetStateAction<SubCategoryModel[]>>;
    setSelectedBrands: React.Dispatch<React.SetStateAction<BrandModel[]>>;
    setMaxCost: (value: number) => void;
    setMinCost: (value: number) => void;
    setCostRange: (value: number[]) => void;
    minCost: number;
    maxCost: number;
    inStock: boolean;
    selectedSubCategories: SubCategoryModel[];
    selectedBrands: BrandModel[];
    filterArray: string[];
    setFilterArray: React.Dispatch<React.SetStateAction<string[]>>;
}

const ActiveFilters: React.FC<ActiveFiltersProps> = ({
                                                         searchQuery,
                                                         setSearchQuery,
                                                         setInStock,
                                                         setSelectedSubCategories,
                                                         setSelectedBrands,
                                                         setMaxCost,
                                                         setMinCost,
                                                         setCostRange,
                                                         minCost, maxCost,
                                                         inStock, selectedSubCategories,
                                                         selectedBrands,
                                                         filterArray,
                                                         setFilterArray
                                                     }) => {
    const handleClearFilters = () => {
        setInStock(false)
        setSelectedSubCategories([]);
        setSelectedBrands([]);
        setSearchQuery('')
        setMaxCost(100000);
        setMinCost(0);
        setCostRange([0, 100000])
        setFilterArray([]);
    };
    const handleRemoveSubCategories = (subCategory: SubCategoryModel) => {
        setSelectedSubCategories(prevState => prevState.filter(subCategories => subCategories !== subCategory));
    };
    const handleRemoveBrand = (brand: BrandModel) => {
        setSelectedBrands((prevState: BrandModel[]) =>
            prevState.filter((existingBrand) => existingBrand.id !== brand.id)
        );
    };
    const handleRemovePrice = () => {
        setMaxCost(100000);
        setMinCost(0);
        setCostRange([0, 100000])
    };

    const handleRemoveFilter = (filterToRemove: string) => {
        setFilterArray((prevFilters) =>
            prevFilters.filter((filter) => filter !== filterToRemove)
        );
    };
    return (
        <div className="filter-results">
            <span className="active-filter">Активные фильтры:</span>

            {searchQuery != '' && (
                <div className="active-filter-name" onClick={() => setSearchQuery('')}>
                    <span className="active-filter-query">{searchQuery}</span>
                    <img src={`${config.cdn}/icons/xmark.svg`} alt="xmark" className="active-filter-btn-delete"/>
                </div>
            )}

            {(minCost > 0 || maxCost < 100000) && (
                <div className="active-filter-name" onClick={handleRemovePrice}>
                    <span>{minCost.toLocaleString('ru-RU')}₽ - {maxCost.toLocaleString('ru-RU')}₽</span>
                    <img src={`${config.cdn}/icons/xmark.svg`} alt="xmark" className="active-filter-btn-delete"/>
                </div>
            )}

            {inStock &&
                <div className="active-filter-name" onClick={() => setInStock(!inStock)}>
                    <span>В наличии</span>
                    <img src={`${config.cdn}/icons/xmark.svg`} alt="xmark" className="active-filter-btn-delete"/>
                </div>}


            {selectedSubCategories.length > 0 && (
                <>
                    {selectedSubCategories.map(subCategory => (
                        <div key={subCategory.id} className="active-filter-name"
                             onClick={() => handleRemoveSubCategories(subCategory)}>
                            <span>{subCategory.name}</span>
                            <img src={`${config.cdn}/icons/xmark.svg`} alt="xmark"
                                 className="active-filter-btn-delete"/>
                        </div>
                    ))}
                </>
            )}

            {selectedBrands.length > 0 && (
                <>
                    {selectedBrands.map(brand => (
                        <div key={brand.id} className="active-filter-name"
                             onClick={() => handleRemoveBrand(brand)}>
                            <span>{brand.name}</span>
                            <img src={`${config.cdn}/icons/xmark.svg`} alt="xmark"
                                 className="active-filter-btn-delete"/>
                        </div>
                    ))}
                </>
            )}

            {filterArray.length > 0 && (
                <>
                    {filterArray.map((filter, index) => {
                        const [key, value] = filter.split(':');
                        return (
                            <div
                                key={index}
                                className="active-filter-name"
                                onClick={() => handleRemoveFilter(filter)}
                            >
                                <span className="active-filter-query">{`${key}: ${value}`}</span>
                                <img
                                    src={`${config.cdn}/icons/xmark.svg`}
                                    alt="xmark"
                                    className="active-filter-btn-delete"
                                />
                            </div>
                        );
                    })}
                </>
            )}

            {(inStock || selectedSubCategories.length > 0 || selectedBrands.length > 0 || minCost > 0 || maxCost < 100000 || filterArray.length > 0) && (
                <span className="active-filter-name clear-filters" onClick={handleClearFilters}>
                                    Удалить все
                                </span>
            )}
        </div>
    );
};

export default ActiveFilters;