import React from "react";
import Config from '../util/Config';
import {CategoryModel} from "../../models/CategoryModel";

interface CategoryViewProps {
    category: CategoryModel;
    brandId?: string | null;
}

const CategoryView: React.FC<CategoryViewProps> = ({category, brandId}) => {
    const categoryLink = brandId
        ? `${Config.urls.categories}/${category.id}?brands=${brandId}`
        : `${Config.urls.categories}/${category.id}`;

    return (
        <>
            <div data-aos="fade" data-aos-delay="100" className="aos-init">
                <a className="category" href={categoryLink} >
                    <div className="category-image-wrapper">
                        <img src={category.imageUri} alt=""/>
                    </div>
                    <div className="category-name-wrapper">{category.name}</div>
                </a>
            </div>
        </>
    );
}

export default CategoryView;
