import Config from "./Config";

const setupHeader = () => {
    const selectBody = document.querySelector('body');
    const selectHeader = document.querySelector('#header');
    const selectLogo: HTMLImageElement | null = document.querySelector('.logo-theme');
    const selectMainBackground = document.querySelector('#hero');

    if (!selectHeader?.classList.contains('scroll-up-sticky') && !selectHeader?.classList.contains('sticky-top') && !selectHeader?.classList.contains('fixed-top')) return;

    if (window.scrollY > 50) {
        selectBody?.classList.add('scrolled');
    } else {
        selectBody?.classList.remove('scrolled');
    }

    if (selectMainBackground) {
        selectBody?.classList.remove('second-page');
        selectBody?.classList.add('index-page');
        if (window.scrollY > 50 ) {
            if (selectLogo) { selectLogo.src = `${Config.cdn}/etc/logo-dark.png` }
        } else {
            if (selectLogo) { selectLogo.src = `${Config.cdn}/etc/logo-white.png` }
        }
    } else {
        if (selectLogo) { selectLogo.src = `${Config.cdn}/etc/logo-dark.png` }
        selectBody?.classList.remove('index-page');
        selectBody?.classList.add('second-page');
    }
}
export default setupHeader;