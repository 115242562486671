import Config from "../js/util/Config";

export interface SubCategoryInterface {
    id: string;
    name: string;
    categoryId: string;
}

export class SubCategoryModel {
    id: string;
    name: string;
    categoryId: string;

    constructor(data: SubCategoryInterface) {
        this.id = data.id;
        this.name = data.name;
        this.categoryId = data.categoryId;
    }

    static fromApi(data: any): SubCategoryModel {
        return new SubCategoryModel({
            id: data.id,
            name: data.name,
            categoryId: data.category_id,
        });
    }
}