import axios from 'axios';
import updateToken from "./updateToken";
import Config from "../util/Config";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(response => {
    return response;
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== Config.api.updateToken) {
        try {
            const response = await updateToken();
            originalRequest.headers.Authorization = `Bearer ${response.tokens.access}`;
            return api(originalRequest);
        } catch {
            throw error;
        }
    }
    throw error;
});

export default api;
