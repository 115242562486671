import React, {useEffect, useState} from "react";
import onStartPageLoaded from "../util/onStartPageLoaded";
import {BusinessLocationModel} from "../../models/BusinessLocationModel";
import Modal from "react-modal";
import NewBusinessLocationModalView from "./NewBusinessLocationModalView";
import formatPhoneNumber from "../util/formatPhoneNumber";
import ActionConfirmModalView from "./ActionConfirmModalView";
import deleteLocation from "../api/deleteLocation";
import {NotificationManager} from "react-notifications";
import fetchAndHandleProfile from "../util/fetchAndHandleProfile";
import {useGlobalContext} from "../util/GlobalProvider";

interface AddressesProps {
    locations: BusinessLocationModel[];
}

const Addresses: React.FC<AddressesProps> = ({locations}) => {
    const {setProfile, setLoggedIn} = useGlobalContext();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

    const handleLocationDeletion = async (locationId: string | null) => {
        if (!locationId) {
            return
        }
        try {
            await deleteLocation(locationId);
            NotificationManager.success('Адрес удалён!', 'Успех');
            await fetchAndHandleProfile(setProfile, setLoggedIn);
        } catch {
            NotificationManager.error('Ошибка при удалении адреса!', 'Ошибка');
        }
    }

    useEffect(() => {
        onStartPageLoaded()
    });

    return (
        <div className="addresses">
            <div className="addresses-div">
                <span className="addresses-component-title">Мои адреса</span>
                {locations.length > 0 && locations.map((location) => (
                    <div className="company-info address">
                        <p className="info-item">
                            <span className="icon">📍</span>
                            <strong className="info-item-address">{location.address}</strong>
                            <button onClick={() => {
                                setSelectedLocation(location.id);
                                setShowDeleteModal(true)
                            }} className="profile-btn-add address-delete">Удалить
                            </button>
                            <ActionConfirmModalView
                                isOpen={showDeleteModal}
                                setShowModal={setShowDeleteModal}
                                onConfirm={() => handleLocationDeletion(selectedLocation)}
                                confirmText="Удалить"
                                cancelText="Отмена"
                                message="Вы точно хотите удалить адрес?"
                            />
                        </p>
                        <p className="info-item">
                            <span className="icon">✉️</span>
                            <strong>Почта</strong>
                            <span className="value"><a href={`mailto:${location.email}`}>{location.email}</a></span>
                        </p>
                        <p className="info-item">
                            <span className="icon">📞</span>
                            <strong>Телефон</strong>
                            <span className="value">{formatPhoneNumber(location.phone)}</span>
                        </p>
                    </div>
                ))}
                {locations.length === 0 && <div className="no-addresses-div">
                    У вас пока нет заказов<br/>
                    Добавьте товары в корзину и оформите заказ
                </div>}
                <div className="no-addresses-div button">
                    <button className="profile-btn-add address" onClick={() => setShowAddModal(true)}>Добавить адрес
                    </button>
                </div>
                <Modal
                    isOpen={showAddModal}
                    onRequestClose={() => setShowAddModal(false)}
                    contentLabel="Новый адрес"
                    overlayClassName="modal-overlay"
                    className="modal-content"
                    closeTimeoutMS={200}
                >
                    <NewBusinessLocationModalView setShowModal={setShowAddModal}/>
                </Modal>
            </div>
        </div>
    );
};

export default Addresses;
