import NdsType from "../js/enums/NdsType";
import ndsType from "../js/enums/NdsType";
import {BusinessLocationInterface, BusinessLocationModel} from "./BusinessLocationModel";

export interface BusinessInterface {
    name: string;
    type: string;
    kpp: string;
    inn: string;
    ogrn: string;
    legalAddress: string;
    locations: BusinessLocationInterface[];
    nds: ndsType;
}

export class BusinessModel {
    name: string;
    type: string;
    kpp: string;
    inn: string;
    ogrn: string;
    legalAddress: string;
    locations: BusinessLocationModel[];
    nds: NdsType;

    constructor(data: BusinessInterface) {
        this.name = data.name;
        this.type = data.type;
        this.kpp = data.kpp;
        this.inn = data.inn;
        this.ogrn = data.ogrn;
        this.legalAddress = data.legalAddress;
        this.locations = data.locations;
        this.nds = data.nds;
    }

    static fromApi(data: any): BusinessModel {
        return new BusinessModel({
            name: data.name,
            type: data.type,
            kpp: data.kpp,
            inn: data.inn,
            ogrn: data.ogrn,
            legalAddress: data.legal_address,
            locations: data.locations ? data.locations.map((location: any) => BusinessLocationModel.fromApi(location)) : [],
            nds: data.nds
        });
    }
}
