import React from 'react';
import Config from '../util/Config';
import {categoryById} from "../util/categoryById";
import {useGlobalContext} from "../util/GlobalProvider";
import {brandById} from "../util/brandById";

interface PathProps {
    categoryId: string | null;
    brandId: string | null;
}

const Path: React.FC<PathProps> = ({ brandId, categoryId}) => {
    const { categories ,brands} = useGlobalContext();
    let childPath;

    if(categoryId) {
        childPath = (
            <>
                <img
                    src={`${Config.cdn}/icons/chevron.right.svg`}
                    alt="chevron"
                />
                <a href={Config.urls.categories}>
                    <span className="catalog-path-name">Каталог</span>
                </a>
                <img
                    src={`${Config.cdn}/icons/chevron.right.svg`}
                    alt="chevron"
                />
                <span className="catalog-path-name">{categoryById(categories, categoryId)?.name}</span>
            </>
        );
    } else if (brandId) {
        childPath = (
            <>
                <img
                    src={`${Config.cdn}/icons/chevron.right.svg`}
                    alt="chevron"
                />
                <a href={Config.urls.categories}>
                    <span className="catalog-path-name">Каталог</span>
                </a>
                <img
                    src={`${Config.cdn}/icons/chevron.right.svg`}
                    alt="chevron"
                />
                <span className="catalog-path-name">{brandById(brands,brandId)?.name}</span>
            </>
        );
    } else {
        childPath = (
            <>
                <img
                    src={`${Config.cdn}/icons/chevron.right.svg`}
                    alt="chevron"
                />
                <span className="catalog-path-name">Каталог</span>
            </>
        );
    }

    return (
        <div className='path'>
            <div className='path-body'>
                <a href={Config.urls.main}>
                    <img src={`${Config.cdn}/icons/home.svg`} alt='home'/>
                    <span className="catalog-path-name">Главная</span>
                </a>
                { childPath }
            </div>
        </div>
    );
};

export default Path;