import AOS from 'aos';
import GLightbox from "glightbox";
import PureCounter from "@srexi/purecounterjs/js/purecounter";
import Imagesloaded from "imagesloaded";
import Isotope from "isotope-layout";
import Config from "./Config";
import setupHeader from "./setupHeader";

const onStartPageLoaded = () => {
    document.addEventListener('scroll', setupHeader);
    window.addEventListener('load', setupHeader);

    /**
     * Mobile nav toggle
     */
    const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');

    function mobileNavToogle() {
        document.querySelector('body')?.classList.toggle('mobile-nav-active');
        mobileNavToggleBtn?.classList.toggle('bi-list');
        mobileNavToggleBtn?.classList.toggle('bi-x');
    }

    mobileNavToggleBtn?.addEventListener('click', mobileNavToogle);

    /**
     * Hide mobile nav on same-page/hash links
     */
    document.querySelectorAll('#navmenu a').forEach(navmenu => {
        navmenu.addEventListener('click', () => {
            if (document.querySelector('.mobile-nav-active')) {
                mobileNavToogle();
            }
        });
    });

    /**
     * Toggle mobile nav dropdowns
     */
    (document.querySelector('.navmenu .toggle-dropdown') as HTMLElement)?.addEventListener('click', function (e: Event) {
        e.preventDefault();
        (this.parentNode as HTMLElement)?.classList.toggle('active');
        (this.parentNode as HTMLElement)?.nextElementSibling?.classList.toggle('dropdown-active');
        e.stopImmediatePropagation();
    });

    /**
     * Preloader
     */
    const preloader = document.querySelector('#preloader');
    if (preloader) {
        window.addEventListener('load', () => {
            preloader.remove();
        });
    }

    /**
     * Scroll top button
     */
    let scrollTop = document.querySelector('.scroll-top');

    function toggleScrollTop() {
        if (scrollTop) {
            window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
        }
    }
    //
    scrollTop?.addEventListener('click', (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
    //
    window.addEventListener('load', toggleScrollTop);
    document.addEventListener('scroll', toggleScrollTop);
    //
    /**
     * Animation on scroll function and init
     */
    const aosInit = () => {
        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }
    window.addEventListener('load', aosInit);

    /**
     * Initiate glightbox
     */
    new GLightbox({
        selector: '.glightbox'
    });

    /**
     * Initiate Pure Counter
     */
    new PureCounter();

    /**
     * Init isotope layout and filters
     */
    document.querySelectorAll('.isotope-layout').forEach(function (isotopeItem) {
        let layout = isotopeItem.getAttribute('data-layout') ?? 'masonry';
        let filter = isotopeItem.getAttribute('data-default-filter') ?? '*';
        let sort = isotopeItem.getAttribute('data-sort') ?? 'original-order';

        let initIsotope: any;
        Imagesloaded(isotopeItem.querySelector('.isotope-container'), function () {
            initIsotope = new Isotope(isotopeItem.querySelector('.isotope-container'), {
                itemSelector: '.isotope-item',
                layoutMode: layout,
                filter: filter,
                sortBy: sort
            });
        });

        isotopeItem.querySelectorAll('.isotope-filters li').forEach(function (this: HTMLElement) {
            this.addEventListener('click', function (this: HTMLElement) {
                isotopeItem.querySelector('.isotope-filters .filter-active')?.classList.remove('filter-active');
                this.classList.add('filter-active');
                initIsotope.arrange({
                    filter: this.getAttribute('data-filter')
                });
                if (typeof aosInit === 'function') {
                    aosInit();
                }
            }, false);
        });
    });

    /**
     * Frequently Asked Questions Toggle
     */
    document.querySelectorAll('.faq-item h3, .faq-item .faq-toggle').forEach((faqItem) => {
        faqItem.addEventListener('click', () => {
            (faqItem.parentNode as HTMLElement)?.classList.toggle('faq-active');
        });
    });

    /**
     * Correct scrolling position upon page load for URLs containing hash links.
     */
    window.addEventListener('load', function (_) {
        if (window.location.hash) {
            if (document.querySelector(window.location.hash)) {
                setTimeout(() => {
                    let section: HTMLElement | null = document.querySelector(window.location.hash);
                    if (section) {
                        let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
                        window.scrollTo({
                            top: section.offsetTop - parseInt(scrollMarginTop),
                            behavior: 'smooth'
                        });
                    }
                }, 100);
            }
        }
    });

    /**
     * Navmenu Scrollspy
     */
    let navmenulinks = document.querySelectorAll('.navmenu a');

    const componentLinks = [Config.urls.main, Config.urls.categories]; // Замените на ваши маршруты

    function navmenuScrollspy() {
        navmenulinks.forEach(navmenulink => {
            const hrefAttr = navmenulink.getAttribute('href');
            if (hrefAttr && componentLinks.includes(hrefAttr)) {
                // Проверка текущего URL
                const currentPath = window.location.pathname;
                const linkPath = navmenulink.getAttribute('href');

                // Если текущий путь совпадает с путем ссылки
                if (currentPath === linkPath) {
                    document.querySelectorAll('.navmenu a.active').forEach(link => link.classList.remove('active'));
                    navmenulink.classList.add('active');
                } else {
                    navmenulink.classList.remove('active'); // Убираем класс active, если не совпадает
                }
            } else if ((navmenulink as HTMLAnchorElement)?.hash) {
                let section: HTMLElement | null = document.querySelector((navmenulink as HTMLAnchorElement)?.hash);
                if (section) {
                    let position = window.scrollY + 200; // Корректируем позицию для учета фиксированного заголовка
                    if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                        document.querySelectorAll('.navmenu a.active').forEach(link => link.classList.remove('active'));
                        navmenulink.classList.add('active');
                    } else {
                        navmenulink.classList.remove('active'); // Убираем класс active, если не попадает в диапазон
                    }
                }
            }
        });
    }

    window.addEventListener('load', navmenuScrollspy);
    document.addEventListener('scroll', navmenuScrollspy);
}

export default onStartPageLoaded;
