import api from "./axios";
import {AxiosError} from "axios";
import FetchProductsByCategoryInterface from "../../models/FetchProductsByCategoryInterface";
import config from "../util/Config";

const fetchProductsByCategory = async (categoryId: string| null, data: FetchProductsByCategoryInterface) => {
    try {
        const response = await api.post(
            `${config.api.fetchProductsByCategory}/${categoryId}`,
            {
                sub_category_ids: data.subCategoryIds,
                brand_ids: data.brandIds,
                cost_min: data.costMin,
                cost_max: data.costMax,
                stock: data.stock,
                sort: data.sort,
                sort_direction: data.sortDirection,
                query: data.query,
                cursor: {
                    cost: data.cursor.cost,
                    score: data.cursor.score,
                    name: data.cursor.name,
                    id: data.cursor.id
                }
            }
        );

        return response.data.products;
    } catch (e) {
        let response = (e as AxiosError).response;
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default fetchProductsByCategory;
