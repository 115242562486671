import Config from "../util/Config";
import Cookies from "js-cookie";
import api from "./axios";
import {AxiosError} from "axios";
import ToggleC1ConfirmInterface from "../../models/ToggleC1ConfirmInterface";

const toggleC1Confirm = async (data: ToggleC1ConfirmInterface) => {
    try {
        await api.post(Config.api.toggleC1Confirm, {
            user_id: data.userId,
            state: data.state,
        }, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access')}`
            }
        });
    } catch (e) {
        let response = (e as AxiosError).response
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default toggleC1Confirm;
