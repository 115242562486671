import { Dispatch, SetStateAction } from 'react';
import { ProductModel } from "../../models/ProductModel";

type CartActionsProps = {
    cartItems: ProductModel[];
    setCartItems: Dispatch<SetStateAction<ProductModel[]>>;
    setShowCartModal: Dispatch<SetStateAction<boolean>>;
};

export const useCartActions = ({  setCartItems}: CartActionsProps) => {
    const handleInputChange = (id: string, newValue: string) => {
        let newAmount = Number(newValue);

        if (!isNaN(newAmount) && newAmount > 0) {
            setCartItems(prevItems => {
                const updatedItems = prevItems.map(item =>
                    item.id === id
                        ? {...item, amountCart: newAmount}
                        : item
                );

                localStorage.setItem('cartItems', JSON.stringify(updatedItems));

                return updatedItems;
            });
        }
    };
    return {
        handleInputChange,
    };
};