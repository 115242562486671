import Config from "../util/Config";
import api from "./axios";
import {AxiosError} from "axios";
import {SearchGroupItemModel} from "../../models/SearchGroupItem";
import FetchProductsBySearchInterface from "../../models/FetchProductsBySearchInterface";

const fetchProductsBySearch = async (data: FetchProductsBySearchInterface): Promise<Array<SearchGroupItemModel>> => {
    try {

        const response = await api.post(
            Config.api.fetchProductsBySearch,
            {
                query: data.query
            }
        );

        if (response.data.results === null) {
            return [];
        }

        return response.data.results.map((item: any): SearchGroupItemModel => {
            return SearchGroupItemModel.fromApi(item, data.categories);
        });
    } catch (e) {
        let response = (e as AxiosError).response;
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default fetchProductsBySearch;
