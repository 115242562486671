import React, {useEffect, useRef, useState} from "react";
import Config from "../util/Config";
import {useCartActions} from '../funcs/cartFunctions';
import SVGCart from "./SVGCart";
import config from "../util/Config";
import {useGlobalContext} from "../util/GlobalProvider";
import ActionConfirmModalView from "./ActionConfirmModalView";
import {NotificationManager} from "react-notifications";

export const Cart = () => {
    const [showCartModal, setShowCartModal] = useState<boolean>(false);
    const { cartItems, removeFromCart, increaseQuantity, decreaseQuantity, setCartItems,profile,loggedIn } = useGlobalContext();
    const modalRef = useRef<HTMLDivElement>(null);
    const modalBtnRef = useRef<HTMLButtonElement>(null);
    const [showClearModal, setShowClearModal] = React.useState(false);
    const { setShowAuthModal} = useGlobalContext();

    const handleCheckoutClick = () => {
        if (!loggedIn) {
            setShowAuthModal(true);
            setShowCartModal(false);
        } else if (profile?.c1Confirmed === false) {
            NotificationManager.warning('Ваш профиль не подтвержден');
            setShowCartModal(false);
        }
        // } else {
        //     setShowAddressModal(true);
        // }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (!showClearModal && modalRef.current && modalBtnRef.current && !modalBtnRef.current.contains(event.target as Node) && !modalRef.current.contains(event.target as Node)) {
            setShowCartModal(false);
        }
    };
    const {
        handleInputChange} = useCartActions({cartItems, setCartItems, setShowCartModal});

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showClearModal]);

    return (
        <>
            <button onClick={() => setShowCartModal(!showCartModal)} className="cart-btn" ref={modalBtnRef}>
                <SVGCart className="cart-btn-img" />
            </button>
            {showCartModal && (
                <div className="cart-modal-container" ref={modalRef}>
                    <button className="cart-close" onClick={() => setShowCartModal(false)}><img src={`${config.cdn}/icons/xmark.svg`} alt="xmark"/></button>
                    <span className="cart-title">Корзина</span>
                    <div className="cart-items-container">
                        {cartItems.length === 0 ?
                            <span className="cart-empty-message">Нет товаров в корзине</span>
                            :
                            cartItems.map(item =>
                                    <div key={item.id} className="cart-product-card">
                                        <div className="cart-img-div">
                                            <img src={item.images[0]} alt="cartImg"/>
                                        </div>
                                        <div className="cart-product-card-info">
                                            <span>{item.name}</span>
                                            <div className="cart-product-card-info-amount">
                                                <button onClick={() => decreaseQuantity(item.id)}
                                                        className="cart-price-btn">-
                                                </button>
                                                <input
                                                    type="number"
                                                    value={item.amountCart}
                                                    className="form-control"
                                                    onChange={(e) => handleInputChange(item.id, e.target.value)}
                                                />
                                                <button onClick={() => increaseQuantity(item.id)}
                                                        className="cart-price-btn">+
                                                </button>
                                            </div>
                                        </div>
                                        <div className="cart-product-card-price">
                            <span>{item.wholeSaleCost.toLocaleString('ru-RU', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })} ₽</span>
                                            <button onClick={() => removeFromCart(item.id)} className="cart-delete-btn">
                                                <img src={`${Config.cdn}/icons/trash.svg`} alt="trash"/>
                                            </button>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                    {cartItems.length > 0 &&
                        <>
                            <button onClick={() => setShowClearModal(true)} className="cart-clean-btn">
                                Очистить корзину
                            </button>
                            <ActionConfirmModalView
                                isOpen={showClearModal}
                                setShowModal={setShowClearModal}
                                onConfirm={() =>setCartItems([])}
                                confirmText="Очистить"
                                cancelText="Отмена"
                                message="Вы уверены, что хотите очистить корзину?"
                            />
                            <button className="checkout-btn" onClick={handleCheckoutClick}>
                                Оформить заказ
                            </button>
                        </>
                    }
                </div>
            )}
        </>
    );
};

export default Cart;