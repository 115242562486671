import fetchClients from "../api/fetchClients";
import {ProfileModel} from "../../models/ProfileModel";

const fetchAndHandleClients = async (setClients: (clients: ProfileModel[]) => void) => {
    try {
        const clientsFetched = await fetchClients();
        setClients(clientsFetched);
    } catch {
        setClients([]);
    }
}

export default fetchAndHandleClients;
