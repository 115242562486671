const getCorrectProductWord = (number: number): string => {
    if (!Number.isInteger(number)) {
        throw new Error('Параметр должен быть целым числом.');
    }

    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return `${number} товаров`;
    } else if (lastDigit === 1) {
        return `${number} товар`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return `${number} товара`;
    } else {
        return `${number} товаров`;
    }
}

export default getCorrectProductWord;
