import Config from "../util/Config";
import {AuthModel} from "../../models/AuthModel";
import api from "./axios";
import {AxiosError} from "axios";
import Cookies from "js-cookie";

const login = async (email: string, password: string): Promise<AuthModel> => {
    return new Promise<AuthModel>(async (resolve, reject) => {
        if (!email || !password) {
            reject(400)
            return
        }
        try {
            const response = await api.post(Config.api.login, {email, password});
            const auth = AuthModel.fromApi(response.data);
            Cookies.set('access', auth.tokens.access, {expires: Config.cookies.access.expireDuration});
            Cookies.set('refresh', auth.tokens.refresh, {expires: Config.cookies.refresh.expireDuration});
            resolve(auth)
        } catch(e) {
            reject((e as AxiosError).status)
            return
        }
    })
}

export default login;
