import React, { useState, useEffect } from 'react';
import {useGlobalContext} from "../util/GlobalProvider";


// Компонент для отображения анимации загрузки
const Loading = () => {
    const { loading } = useGlobalContext(); // Получаем статус загрузки из глобального контекста
    const [preloading, setPreloading] = useState(true); // Состояние для управления предварительной загрузкой

    useEffect(() => {
        if (!loading) {
            // Задержка позволяет анимации завершиться плавно
            setTimeout(() => setPreloading(false), 300);
        }
    }, [loading]);

    return (
            <div className={`page-loader-container ${loading ? '' : 'loaded'}`} style={preloading ? {} : {display: "none"}}>
                <span className={`loader ${loading ? '' : 'loaded'}`}></span>
            </div>
    )
}

export default Loading;