import {BrandModel} from "../../models/BrandModel";
import Config from "../util/Config";
import api from "./axios";
import {AxiosError} from "axios";

const fetchBrands = async () => {
    return new Promise<BrandModel[]>(async (resolve, reject) => {
        try {
            const response = await api.get(Config.api.fetchBrands);
            resolve(response.data.brands.map((el: any) => BrandModel.fromApi(el)));
        } catch(e) {
            reject((e as AxiosError).status)
            return
        }
    });
};

export default fetchBrands;
