import {createRoot} from "react-dom/client";
import React from "react";
import {GlobalProvider} from "./js/util/GlobalProvider";
import MainPage from "./js/pages/MainPage";

const rootElement = document.getElementById('root')
if (rootElement) {
    createRoot(rootElement).render(
        <GlobalProvider>
            <MainPage/>
        </GlobalProvider>
    );
} else {
    throw new Error("Unable to find root element");
}
