import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";

import Config from "../util/Config";



const ImageSlider = () => {

    return (
        <Swiper
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        data-aos="fade-in"
        className="aos-init img-bg-swiper"
        effect={'fade'}
        speed={1500}
        modules={[EffectFade, Autoplay]}
      >
        <SwiperSlide><img src={`${Config.cdn}/etc/main-bg1.png`} alt=''/></SwiperSlide>
        <SwiperSlide><img src={`${Config.cdn}/etc/main-bg2.png`} alt=''/></SwiperSlide>
        <SwiperSlide><img src={`${Config.cdn}/etc/main-bg3.png`} alt=''/></SwiperSlide>
        <SwiperSlide><img src={`${Config.cdn}/etc/main-bg4.png`} alt=''/></SwiperSlide>
        <SwiperSlide><img src={`${Config.cdn}/etc/main-bg5.png`} alt=''/></SwiperSlide>
      </Swiper>

    );
};

export default ImageSlider;