import Config from "../util/Config";
import Cookies from "js-cookie";
import api from "./axios";
import {AxiosError} from "axios";
import UpdateUserInterface from "../../models/UpdateUserInterface";

const updateUser = async (data: UpdateUserInterface) => {
    try {
        await api.post(Config.api.updateUser, {
            first_name: data.firstName,
            last_name: data.lastName,
            father_name: data.fatherName,
            phone: data.phone,
            inn: data.inn,
            nds: data.nds,
        }, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access')}`
            }
        });
    } catch (e) {
        let response = (e as AxiosError).response
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default updateUser;
