import {SVGProps} from "react";

const SVGCart = (props: SVGProps<object>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="46.967"
            height="42.1833"
            viewBox="0 0 46.967 42.1833"
            preserveAspectRatio="xMidYMid meet"
            className={props.className}
        >
            <path
                d="M16.0093 32.2129L38.7029 32.2129C39.7317 32.2129 40.6504 31.4389 40.6504 30.2885C40.6504 29.1443 39.7317 28.3827 38.7029 28.3827L16.5215 28.3827C15.6939 28.3827 15.1842 27.7877 15.0581 26.9069L11.9854 5.65303C11.7404 3.83859 10.9513 2.9216 8.6548 2.9216L2.07881 2.9216C0.962181 2.9216 0 3.88468 0 5.01994C0 6.16232 0.962181 7.13161 2.07881 7.13161L7.8916 7.13161L10.8427 27.3099C11.2901 30.3566 12.9289 32.2129 16.0093 32.2129ZM12.7261 24.9058L38.9283 24.9058C42.0097 24.9058 43.6608 23.0486 44.1021 19.9699L45.5093 10.5385C45.5483 10.2659 45.5998 9.92858 45.5998 9.67827C45.5998 8.43023 44.7414 7.54348 43.2138 7.54348L10.94 7.54348L10.9533 11.3933L41.0684 11.3933L39.9177 19.6184C39.7987 20.5188 39.3281 21.0693 38.4739 21.0693L12.6995 21.0693ZM17.6393 42.1833C19.6144 42.1833 21.1884 40.6165 21.1884 38.628C21.1884 36.6652 19.6144 35.0789 17.6393 35.0789C15.6632 35.0789 14.0706 36.6652 14.0706 38.628C14.0706 40.6165 15.6632 42.1833 17.6393 42.1833ZM35.547 42.1833C37.5293 42.1833 39.1094 40.6165 39.1094 38.628C39.1094 36.6652 37.5293 35.0789 35.547 35.0789C33.5842 35.0789 31.9845 36.6652 31.9845 38.628C31.9845 40.6165 33.5842 42.1833 35.547 42.1833Z"
            />
        </svg>
    );
};

export default SVGCart;