import React from "react";
import Modal from "react-modal"; // Импортируем модальное окно из react-modal

interface ActionConfirmModalViewProps {
    isOpen: boolean; // Состояние открытия модального окна
    setShowModal: (show: boolean) => void; // Функция для управления видимостью модального окна
    onConfirm: () => void; // Функция, которая выполняет действие при подтверждении
    confirmText?: string; // Текст на кнопке подтверждения
    cancelText?: string; // Текст на кнопке отмены
    message: string; // Сообщение в модальном окне
}

const ActionConfirmModalView: React.FC<ActionConfirmModalViewProps> = ({
                                                                           isOpen,
                                                                           setShowModal,
                                                                           onConfirm,
                                                                           confirmText = "Подтвердить",
                                                                           cancelText = "Отмена",
                                                                           message
                                                                       }) => {
    const handleConfirm = () => {
        onConfirm();
        setShowModal(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Подтверждение действия"
            overlayClassName="modal-overlay"
            className="modal-content"
            closeTimeoutMS={400}
        >
            <div className="common-div-form">
                <button onClick={() => setShowModal(false)} className="close-modal-btn">×</button>
                <h5>Подтверждение действия</h5>
                <p>{message}</p>
                <div style={{width: 'inherit'}}>
                    <button
                        onClick={handleConfirm}
                        className="common-btn action-modal"
                    >
                        {confirmText}
                    </button>
                    <button
                        onClick={() => setShowModal(false)}
                        className="common-btn action-modal cancel-btn"
                    >
                        {cancelText}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ActionConfirmModalView;
