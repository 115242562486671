import {AuthModel} from "../../models/AuthModel";
import Config from "../util/Config";
import api from "./axios";
import {AxiosError} from "axios";

const register = async (email: string, password: string) => {
    try {
        const response = await api.post(
            Config.api.register,
            {email: email, password: password},
        );
        return AuthModel.fromApi(response.data);
    } catch (e) {
        throw (e as AxiosError).status
    }
}

export default register;
