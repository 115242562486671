import onStartPageLoaded from "../util/onStartPageLoaded";
import Start from "../components/Start";
import React, {useEffect} from 'react';
import setupHeader from "../util/setupHeader";
import {useSearchParams} from "react-router-dom";
import {  NotificationManager } from 'react-notifications';
import config from "../util/Config";

const StartPage = () => {
    const [searchParams,setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    useEffect(() => {
        onStartPageLoaded();
        setupHeader();
        if (token !== null) {
            fetch(config.api.verifyEmail , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token: token})
            }).then(response => {
                if (response.ok) {
                    NotificationManager.success('Ваша почта была успешно подтверждена!', 'Успех');
                    setSearchParams({}, { replace: true });
                } else {
                    NotificationManager.error('Произошла ошибка при подтверждении почты!', 'Ошибка');
                }
            }).catch((error) => {
                NotificationManager.error('Произошла ошибка при подтверждении почты!', 'Ошибка');
            });
        }
    }, [token]);
    return (
        <>
            <Start/>
        </>
        );
};

export default StartPage;
