import Config from "../js/util/Config";

export interface ProductInterface {
    // Meta
    id: string;
    created: Date;
    stockNds: number;
    stockNoNds: number;


    // Content
    name: string;
    vendor: string;
    categoryId: string;
    subCategoryId: string;
    brandId: string;
    images: string[];
    wholeSaleCost: number;
    retailCost: number;
    available: boolean;

    // Optional
    material: string;
    country: string;
    guarantee: string;
    description: string;

    amountCart: number;

    // Optional Custom
    // TODO: Add here all other custom fields from back-end
}

export class ProductModel {
    id: string;
    created: Date;
    stockNds: number;
    stockNoNds: number;
    name: string;
    vendor: string;
    categoryId: string;
    subCategoryId: string;
    brandId: string;
    images: string[];
    wholeSaleCost: number;
    retailCost: number;
    available: boolean;
    material: string;
    country: string;
    guarantee: string;
    description: string;
    amountCart: number ;


    constructor(data: ProductInterface) {
        this.id = data.id;
        this.created = new Date(data.created);
        this.stockNds = data.stockNds;
        this.stockNoNds  = data.stockNoNds;
        this.name = data.name;
        this.vendor = data.vendor;
        this.categoryId = data.categoryId;
        this.subCategoryId = data.subCategoryId;
        this.brandId = data.brandId;
        this.images = data.images;
        this.wholeSaleCost = data.wholeSaleCost;
        this.retailCost = data.retailCost;
        this.available = data.available;
        this.material = data.material;
        this.country = data.country;
        this.guarantee = data.guarantee;
        this.description = data.description;
        this.amountCart = data.amountCart || 0;
    }

    static fromApi(data: any): ProductModel {
        return new ProductModel({
            id: data.id,
            created: new Date(data.created),
            stockNds: data.stock_nds,
            stockNoNds: data.stockstock_no_nds,
            name: data.name,
            vendor: data.vendor,
            categoryId: data.category_id,
            subCategoryId: data.sub_category_id,
            brandId: data.brand_id,
            images: data.images ? data.images.map((imageId: string) =>{
                if (/^https?:\/\//i.test(imageId)) {
                    return `${Config.cdn}/etc/img-placeholder.png`;
                } else {
                    return `${Config.cdn}/products/${imageId}`;
                }
            }): [`${Config.cdn}/etc/img-placeholder.png`],
            wholeSaleCost: data.whole_sale_cost,
            retailCost: data.retail_cost,
            available: data.available,
            material: data.material,
            country: data.country,
            guarantee: data.guarantee,
            description: data.description,
            amountCart: data.amountCart !== undefined ? data.amountCart : 0
        });
    }
    static fromLocalStorage(data: any): ProductModel {
        return new ProductModel({
            id: data.id,
            name: data.name,
            wholeSaleCost: data.price,
            retailCost: data.price,
            stockNds: data.stockNds,
            stockNoNds: data.stockNoNds,
            created: new Date(),
            brandId: '',
            categoryId: '',
            subCategoryId: '',
            vendor: '',
            images: [],
            guarantee: '',
            material: '',
            country: '',
            available: true,  // or false, depending on your logic
            description: data.description || '',
            amountCart: data.amountCart || 0
        });
    }
}
