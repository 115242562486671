import Config from "../util/Config";
import {ProfileModel} from "../../models/ProfileModel";
import api from "./axios";
import {AxiosError} from "axios";
import Cookies from "js-cookie";

const fetchProfile = async (): Promise<ProfileModel> => {
    try {
        let response = await api.get(Config.api.profile, {headers: {Authorization: `Bearer ${Cookies.get('access')}`}})
        return ProfileModel.fromApi(response.data);
    } catch (e) {
        Cookies.remove('access');
        throw (e as AxiosError).status;
    }
}
export default fetchProfile;
