import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Outlet} from 'react-router-dom';
import Cookies from "js-cookie";
import {useGlobalContext} from "./GlobalProvider";
import Config from '../util/Config';

const PrivateRoute = () => {
    const { loggedIn } = useGlobalContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loggedIn) {
            navigate(Config.urls.main);
        }

    }, [loggedIn, navigate]);

    if (loggedIn) {
        return <Outlet />;
    }

    return null;
};

export default PrivateRoute;