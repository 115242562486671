export interface BusinessLocationInterface {
    id: string;
    address: string;
    email: string;
    phone: string;
}

export class BusinessLocationModel {
    id: string;
    address: string;
    email: string;
    phone: string;

    constructor(data: BusinessLocationInterface) {
        this.id = data.id;
        this.address = data.address;
        this.email = data.email;
        this.phone = data.phone;
    }

    static fromApi(data: any): BusinessLocationModel {
        return new BusinessLocationModel({
            id: data.id,
            address: data.real_address,
            email: data.email,
            phone: data.phone,
        });
    }
}
