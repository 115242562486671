import React, {useEffect} from "react";
import onStartPageLoaded from "../util/onStartPageLoaded";
import {ProfileModel} from "../../models/ProfileModel";
import Client from "./Client";

interface ClientsProps {
    clients: ProfileModel[];
}

const Clients: React.FC<ClientsProps> = ({clients}) => {
    useEffect(() => {
        onStartPageLoaded()
    });

    return (
        <div className="clients">
            <div className="clients-div">
                <span className="clients-component-title">Клиенты</span>
                {clients.length > 0 && clients.map((client) => <Client client={client}/>)}
                {clients.length === 0 && <div className="no-clients-div">
                    Нет ни одоного зарегистрированного клиента
                </div>}
            </div>
        </div>
    );
};

export default Clients;
