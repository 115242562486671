import formatPhoneNumber from "../util/formatPhoneNumber";
import Toggle from "./Toggle";
import ClientRole from "../enums/ClientRole";
import React from "react";
import {ProfileModel} from "../../models/ProfileModel";
import toggleC1Confirm from "../api/toggleC1Confirm";
import {useGlobalContext} from "../util/GlobalProvider";
import fetchAndHandleProfile from "../util/fetchAndHandleProfile";
import fetchAndHandleClients from "../util/fetchAndHandleClients";

interface ClientProps {
    client: ProfileModel;
}

const Client: React.FC<ClientProps> = ({client}) => {
    const [c1Confirmed, setC1Confirmed] = React.useState(client.c1Confirmed);
    const {profile, setProfile, setLoggedIn, setClients} = useGlobalContext();

    const handleToggleC1Confirm = async () => {
        const newValue = !c1Confirmed;
        setC1Confirmed(newValue);
        try {
            await toggleC1Confirm({userId: client.id, state: newValue});
            if (client.id === profile?.id) {
                await fetchAndHandleProfile(setProfile, setLoggedIn);
            }
            await fetchAndHandleClients(setClients);
        } catch {
            setC1Confirmed(!newValue);
        }
    }

    const showName = client.firstName || client.lastName || client.fatherName ? `${client.lastName} ${client.firstName} ${client.fatherName}` : '< Не указано >';
    const showEmail = client.email ?
        <a href={`mailto:${client.email}`}>{client.email}</a> : '< Не указано >';
    const showPhone = client.phone ?
        <a href={`tel:${client.phone}`}>{formatPhoneNumber(client.phone)}</a> : '< Не указано >';
    const showInn = client.business?.inn ? client.business?.inn : '< Не указано >';

    return (
        <div className="company-info client">
            <p className="info-item">
                <span className="icon">👨‍💼</span>
                <strong className="info-item-client">{showName}</strong>
                <Toggle className="" isActive={c1Confirmed} onToggle={handleToggleC1Confirm}/>
            </p>
            <p className="info-item">
                <span className="icon">✉️</span>
                <strong>Почта</strong>
                <span className="value">{showEmail}</span>
            </p>
            <p className="info-item">
                <span className="icon">📞</span>
                <strong>Телефон</strong>
                <span className="value">{showPhone}</span>
            </p>
            <p className="info-item">
                <span className="icon">🏛️</span>
                <strong>ИНН</strong>
                <span className="value">{showInn}</span>
            </p>
            <p className="info-item">
                <span className="icon">🏷️ </span>
                <strong>Роль</strong>
                <span
                    className="value">{client.role === ClientRole.admin ? 'Администратор' : 'Клиент'}</span>
            </p>
        </div>
    );
}

export default Client;
