import React, {useEffect, useState} from 'react';
import {ProductModel} from "../../models/ProductModel";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation, Thumbs} from 'swiper/modules';
import fetchProduct from "../api/fetchProduct";
import {useNavigate, useParams} from "react-router-dom";
import {useGlobalContext} from "../util/GlobalProvider";
import Config from "../util/Config";
import Loading from "../components/Loading";
import {
    handleInputChange,
} from "../hooks/AddToCart";


const ProductPage: React.FC = () => {
    const {categories,brands,subCategories} = useGlobalContext();
    const { productId } = useParams<{ productId: string }>();
    const [product, setProduct] = useState<ProductModel | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    const navigate = useNavigate();
    const { cartItems, addToCart, removeFromCart, increaseQuantity, decreaseQuantity, setCartItems } = useGlobalContext();

    useEffect(() => {
        if (!productId) return;
        const loadProductData = async () => {
            try {
                const productData = await fetchProduct(productId);
                const productModel = ProductModel.fromApi(productData);
                setProduct(productModel);
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };
        loadProductData();
    }, [productId]);


    const isProductInCart = product ? cartItems.some(item => item.id === product.id) : false;


    if (loading)
        return <Loading/>

    if (error){
        navigate(Config.urls.main);
        return null
    }

    if (!product){
        navigate(Config.urls.main);
        return null
    }
    const category = categories.find(cat => cat.id === product.categoryId);
    const subCategory = subCategories.find(sub => sub.id === product.subCategoryId);
    const brand = brands.find(brand => brand.id === product.brandId)
    return (
        <div className="product-page">
            <h3 style={{marginBottom: "20px", fontSize:"22px"}}>{product.name}</h3>
            <div className="product-page-containt">
                <div className="product-left">
                    {product.images &&(
                    <div className="product-img-slider">
                        <Swiper
                            style={{
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff',
                            }}
                            loop={true}
                            spaceBetween={10}
                            thumbs={{swiper: thumbsSwiper}}
                            modules={[FreeMode, Thumbs, Navigation]}
                            className="mySwiper2"
                            navigation={true}
                        >
                            {product.images.map((image, index) =>
                                <SwiperSlide key={index}>
                                    <img src={image} alt={`${product.name}`}/>
                                </SwiperSlide>)
                            }
                        </Swiper>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            loop={true}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                        >
                            {product.images.map((image, index) =>
                                <SwiperSlide key={index}>
                                    <img src={image} alt={`Product ${product.name}`}/>
                                </SwiperSlide>)
                            }
                        </Swiper>
                    </div>)}
                    {product.description && (<h3>Описание</h3>)}
                    <span>{product.description}</span>
                </div>
                <div className="product-right">

                    <h4 className="product-h">Характеристики</h4>
                    <div className="characteristics">
                        <div className="characteristics-row">
                            <div className="characteristic-name">Артикул</div>
                            <div className="characteristic-value">{product.vendor}</div>
                        </div>
                        <div className="characteristics-row colored">
                            <div className="characteristic-name">Категория</div>
                            <div className="characteristic-value">{category?.name}</div>
                        </div>
                        <div className="characteristics-row">
                            <div className="characteristic-name">Подкатегория</div>
                            <div className="characteristic-value">{subCategory?.name}</div>
                        </div>
                        <div className="characteristics-row colored">
                            <div className="characteristic-name">Бренд</div>
                            <div className="characteristic-value">{brand?.name}</div>
                        </div>
                        <div className="characteristics-row">
                            <div className="characteristic-name">Страна</div>
                            <div className="characteristic-value">{product.country}</div>
                        </div>
                        <div className="characteristics-row colored">
                            <div className="characteristic-name">Материал</div>
                            <div className="characteristic-value">{product.material}</div>
                        </div>
                        <div className="characteristics-row">
                            <div className="characteristic-name">Гарантия</div>
                            <div className="characteristic-value">{product.guarantee}</div>
                        </div>
                        <div className="characteristics-row colored">
                            <div className="characteristic-name">Наличие</div>
                            <div className="characteristic-value">{product.stockNds} шт.</div>
                        </div>
                        <div className="characteristics-row">
                            <div className="characteristic-name">РРЦ</div>
                            <div className="characteristic-value">{product.retailCost} ₽</div>
                        </div>
                    </div>
                    <div className="product-price-contain">
                        <h3>{product.wholeSaleCost.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} ₽</h3>
                        {isProductInCart  ? (
                            <div className="product-page-cart">
                                <div className="product-page-cart-amount">
                                    <button onClick={() => decreaseQuantity(product.id)} className="cart-price-btn">
                                        -
                                    </button>
                                    <input
                                        type="number"
                                        value={cartItems.find(item => item.id === product.id)?.amountCart || 1}
                                        className="form-control"
                                        onChange={(e) => handleInputChange(product.id, e.target.value, setCartItems)}
                                    />
                                    <button onClick={() => increaseQuantity(product.id)} className="cart-price-btn">
                                        +
                                    </button>
                                    <button
                                        style={{marginLeft:"10px"}}
                                        onClick={() => removeFromCart(product.id)}
                                        className="cart-delete-btn"
                                    >
                                        <img src={`${Config.cdn}/icons/trash.svg`} alt="trash" />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <button className="product-btn-basket" onClick={() => addToCart(product)}>
                                В корзину
                            </button>
                        )}
                    </div>

                </div>
            </div>
        </div>

    );
};

export default ProductPage;