import Config from "../util/Config";
import api from "./axios";
import {AxiosError} from "axios";
import Cookies from "js-cookie";
import {ProfileModel} from "../../models/ProfileModel";

const fetchClients = async (): Promise<Array<ProfileModel>> => {
    try {
        let response = await api.get(Config.api.fetchClients, {headers: {Authorization: `Bearer ${Cookies.get('access')}`}})
        return response.data.clients.map((el: any): ProfileModel => {
            return ProfileModel.fromApi(el);
        })
    } catch (e) {
        console.log(e);
        throw (e as AxiosError).status;
    }
}

export default fetchClients;
