import React, {useState} from "react";
import Config from "../util/Config";
import { Button, Popover, Whisper} from "rsuite";
import {useGlobalContext} from "../util/GlobalProvider";
import {ProductModel} from "../../models/ProductModel";
import ProductCardViewType from "../enums/ProductCardViewType";
import {  NotificationManager } from 'react-notifications';

interface ProductCardProps {
    product: ProductModel;
    view: ProductCardViewType;
    categoryId: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, view ,categoryId}) => {
    const [addedToCart] = useState<boolean>(false);
    const [hovered, setHovered] = React.useState<boolean>(false);
    const { showRetailCost,  showWholeSaleCost  } = useGlobalContext();
    const { cartItems , addToCart, removeFromCart} = useGlobalContext();
    const isProductInCart = product ? cartItems.some(item => item.id === product.id) : false;

    const handleCartButtonClick = () => {
        if (isProductInCart) {
            removeFromCart(product.id);
        } else {
            addToCart(product);
        }

    };
    const handleCardClick = () => {
        window.open(`${Config.urls.categories}/${categoryId}/${product.id}`, '_blank');
    };
    const handleCopy = (textToCopy: string) => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                NotificationManager.info('Артикул скопирован!');
            })
            .catch(err => {
                console.error('Не удалось скопировать текст:', err);
            });
    };
    return view === ProductCardViewType.grid ? (
        <div>
            <div className="product-card product-card-grid"
                 onMouseEnter={() => setHovered(true)}
                 onMouseLeave={() => setHovered(false)}
                 onClick={handleCardClick}
            >
                <div className={`product-card-grid-img-bg ${hovered || addedToCart ? 'hovered' : ''}`}>
                    <Whisper delayOpen={500} placement={"auto"} followCursor
                             speaker={<Popover><img className="product-card-grid-img-hint" src={product.images?.[0]}
                                                    alt="good"/></Popover>}>
                        <img className="product-card-grid-img" src={product.images?.[0]} alt="good"/>
                    </Whisper>
                </div>
                <div className="product-card-grid-text">
                    <div className="product-card-grid-name">{product.name}</div>
                    <div>
                        <div className="product-card-grid-retailccst-container">
                            <Whisper followCursor speaker={<Popover>Розничная цена</Popover>}>
                                {showRetailCost ? <img className="product-card-grid-retailcost-icon"
                                                       src={`${Config.cdn}/icons/person.svg`} alt="person"/> : null}
                            </Whisper>
                            {showRetailCost ? <div
                                className="product-card-grid-retailcost">₽ {product.retailCost?.toLocaleString('ru-RU', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div> : null}
                        </div>
                        <div className="product-card-grid-wholesale-container">
                            <Whisper followCursor speaker={<Popover>Оптовая цена</Popover>}>
                                {showWholeSaleCost ? <img className="product-card-grid-wholesale-icon"
                                                          src={`${Config.cdn}/icons/building.2.svg`} alt="person"/> : null}
                            </Whisper>
                            {showWholeSaleCost ? <div
                                className="product-card-grid-wholesale">₽ {product.wholeSaleCost?.toLocaleString('ru-RU', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div> : null}
                        </div>
                    </div>
                </div>
                <div
                    className={`product-card-grid-stock ${hovered || isProductInCart ? 'hovered' : ''}`}>{product.stockNds > 0 ? `${product.stockNds} шт.` : 'Под заказ'}</div>
                <div className={`product-card-grid-cart-container ${hovered || isProductInCart ? 'hovered' : ''}`}
                     onClick={(e) => e.stopPropagation()}>
                    <Button onClick={() => {
                        handleCartButtonClick()
                    }} appearance={isProductInCart ? 'default' : 'primary'}
                            className={isProductInCart ? 'remove' : 'add'}>{isProductInCart ? "Убрать из корзины" : "Добавить в корзину"}</Button>
                </div>
            </div>
        </div>
    ) : (
        <div>
            <div className="product-card product-card-list"
                 onMouseEnter={() => setHovered(true)}
                 onMouseLeave={() => setHovered(false)}
                 onClick={handleCardClick}
            >
                <div className={`product-card-list-content ${hovered || addedToCart ? 'hovered' : ''}`}>
                    <Whisper delayOpen={500} placement={"auto"} followCursor
                             speaker={<Popover><img className="product-card-list-img-hint" src={product.images?.[0]}
                                                    alt="good"/></Popover>}>
                        <img className="product-card-list-img" src={product.images?.[0]} alt="good"/>
                    </Whisper>
                    <div className="product-card-list-name">{product.name}</div>
                    <div
                        className="product-card-list-stock">{product.stockNds > 0 ? `${product.stockNds} шт.` : 'Под заказ'}</div>
                    <div className="product-card-list-article" onClick={(e) => {e.stopPropagation();handleCopy(product.vendor); }}> арт. {product.vendor}</div>
                    <Whisper placement={"left"} followCursor speaker={<Popover>Розничная цена</Popover>}>
                        {showRetailCost ?
                            <img className="product-card-list-retailcost-icon" src={`${Config.cdn}/icons/person.svg`}
                                 alt="person"/> : null}
                    </Whisper>
                    {showRetailCost ? <div
                        className="product-card-list-retailcost">₽ {product.retailCost?.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</div> : null}
                    <Whisper placement={"left"} followCursor speaker={<Popover>Оптовая цена</Popover>}>
                        {showWholeSaleCost ?
                            <img className="product-card-list-wholesalecost-icon"
                                 src={`${Config.cdn}/icons/building.2.svg`}
                                 alt="person"/> : null}
                    </Whisper>
                    {showWholeSaleCost ? <div
                        className="product-card-list-wholesalecost">₽ {product.wholeSaleCost?.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</div> : null}
                </div>
                <div className={`product-card-list-cart ${hovered || isProductInCart ? 'hovered' : ''}`}
                     onClick={(e) => e.stopPropagation()}>
                    <Button
                        onClick={() => {
                            handleCartButtonClick()
                        }}
                        appearance={isProductInCart ? 'default' : 'primary'}
                        className={addedToCart ? 'remove' : 'add'}>
                        <img className="icon" src={addedToCart ? `${Config.cdn}/icons/xmark.circle.svg` : `${Config.cdn}/icons/cart.svg`}  alt="person"></img>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;