import Config from "../js/util/Config";

export interface BrandInterface {
    id: string;
    name: string;
    imageUri: string  | null;
}

export class BrandModel {
    id: string;
    name: string;
    imageUri: string | null;

    constructor(data: BrandInterface) {
        this.id = data.id;
        this.name = data.name;
        this.imageUri = data.imageUri;
    }

    static fromApi(data: any): BrandModel {
        const imageUri = data.image_id ? `${Config.cdn}/brands/${data.image_id}.png` : null;
        return new BrandModel({
            id: data.id,
            name: data.name,
            imageUri,
        });
    }
}