// useLoadProducts.ts
import { useState, useEffect, useCallback } from 'react';
import FetchProductsByCategoryInterface, {
    FetchProductsByCategoryCursorInterface
} from "../../models/FetchProductsByCategoryInterface";
import {ProductModel} from "../../models/ProductModel";
import {loadProducts} from "./ProductCards";


interface UseLoadProductsProps {
    categoryId: string | null;
    productFilters: FetchProductsByCategoryInterface;
}

interface UseLoadProductsReturn {
    products: ProductModel[];
    loading: boolean;
    error: Error | null;
    loadMoreProducts: () => void;
    hasMore: boolean;
}

const useLoadProducts = ({ categoryId, productFilters }: UseLoadProductsProps): UseLoadProductsReturn => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [cursor, setCursor] = useState<FetchProductsByCategoryCursorInterface | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [debouncedQuery, setDebouncedQuery] = useState(productFilters);

    const loadProductsWrapper = useCallback(() => {
        if (!hasMore) return;
        loadProducts(categoryId, productFilters, cursor, setLoading, setProducts, setError, setHasMore);
    }, [productFilters, cursor, hasMore]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(productFilters);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [productFilters]);


    useEffect(() => {
        setHasMore(true);
        setProducts([]);
        setCursor(null);
        loadProductsWrapper();
    }, [debouncedQuery]);

    useEffect(() => {
        if (hasMore && products.length === 0 && cursor === null) {
            loadProductsWrapper();
        }
    }, [hasMore, products, cursor]);

    useEffect(() => {
        if (cursor) {
            loadProductsWrapper();
        }
    }, [cursor]);

    const loadMoreProducts = useCallback(() => {
        if (!loading && !error && products.length > 0 && hasMore) {
            const lastProduct = products[products.length - 1];
            if (lastProduct.wholeSaleCost !== undefined) {
                setCursor({
                    id: lastProduct.id,
                    name: lastProduct.name,
                    cost: lastProduct.wholeSaleCost,
                    score: null
                });
            } else {
                console.warn("Warning: lastProduct.wholeSaleCost is undefined", lastProduct);
            }
        }
    }, [loading, error, products, hasMore]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight -286) {
            loadMoreProducts();
        }
    }, [loadMoreProducts]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return { products, loading, error, loadMoreProducts, hasMore };
};

export default useLoadProducts;
