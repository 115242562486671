import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import React, {useEffect, useState} from 'react';
import CategoryMiniCardView from "./CategoryMiniCardView";
import BrandCard from "./BrandCard";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import ImageSlider from "./StartImageSlider";
import SearchDropdown from "./SearchDropdown";
import {useGlobalContext} from "../util/GlobalProvider";
import {SearchGroupItemModel} from "../../models/SearchGroupItem";
import fetchProductsBySearch from "../api/fetchProductsBySearch";
import {NotificationManager} from "react-notifications";

const Start = () => {
    const {categories, subCategories, brands, searchQuery, setSearchQuery} = useGlobalContext();
    const [searchResults, setSearchResults] = useState<Array<SearchGroupItemModel>>([]);
    const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

    const handleSearchQueryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value ?? searchQuery);
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedQuery === "") {
            setSearchResults([]);
            return;
        }

        const fetchData = async () => {
            try {
                const results = await fetchProductsBySearch({ query: debouncedQuery, categories });
                setSearchResults(results);
            } catch (error) {
                NotificationManager.error('Ошибка при поиске товаров', 'Ошибка');
            }
        };

        fetchData().then();
    }, [debouncedQuery, categories]);

    return (
        <div className="main">
            {/*Hero Section*/}
            <section id="hero" className="hero section dark-background">
                <ImageSlider/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <h2 data-aos="fade-up" data-aos-delay="100" className="aos-init hero-text">Сантехника оптом
                                по всей России</h2>
                            <p data-aos="fade-up" data-aos-delay="200" className="aos-init hero-text">Ваш надежный
                                партнер в мире сантехники</p>
                        </div>
                        <div className="col-lg-5 aos-init" data-aos="fade-up" data-aos-delay="300">
                            <form action="" method="post" className="email-form">
                                <div className="sign-up-form">
                                    <input onChange={handleSearchQueryChange} type="text" name="searchQuery" placeholder="Поиск"/>
                                    {/*<img className="main-search-icon" src={`${Config.cdn}/icons/magnifyingglass.svg`}*/}
                                    {/*     alt="Искать"/>*/}
                                </div>
                            </form>
                            {searchResults.length > 0 && <SearchDropdown items={searchResults} query={searchQuery}/>}
                        </div>
                    </div>
                </div>
            </section>
            {/*Hero Section*/}
            {/*Brands Section*/}
            <section id="clients" className="clients section">

                <div className="container" data-aos="fade-up">

                    <div className="row" style={{margin: '0 auto', width: '94%', alignItems: 'center'}}>
                        <Swiper
                            modules={[Autoplay, Navigation]}
                            spaceBetween={20}
                            slidesPerView={6}
                            loop={true}
                            autoplay={{
                                delay: 8000,
                                disableOnInteraction: false,
                            }}
                            navigation={{
                                nextEl: '.swiper-button-next-custom',
                                prevEl: '.swiper-button-prev-custom',
                            }}
                            speed={1500}
                            effect="fade"
                            className="swiper">
                            {
                                brands.map(
                                    brand => (
                                        brand.imageUri &&
                                        <SwiperSlide key={brand.id}
                                                     style={{height: '100px', alignItems: 'center', display: 'flex'}}>
                                            <BrandCard brand={brand} />
                                        </SwiperSlide>
                                    )
                                )
                            }
                        </Swiper>
                        <div className="swiper-button-prev swiper-button-prev-custom"></div>
                        <div className="swiper-button-next swiper-button-next-custom"></div>
                    </div>
                </div>
            </section>
            {/*  /Brands Section  */}
            <section id="categories" className="categories-selection">
                <div className="section-title section-title aos-init" data-aos="fade-up">
                    <h2>Категории</h2>
                </div>
                <div className="categories-selection-grid">
                    {
                        categories.map(
                            (category) => (
                                <CategoryMiniCardView key={category.id}
                                                      category={category}
                                                      subCategories={subCategories.filter(subCategory => subCategory.categoryId === category.id)}
                                />
                            )
                        )
                    }
                </div>
            </section>
            {/*  Contact Section  */}
            <section id="contact" className="contact section">
                {/*  Section Title  */}
                <div className="container section-title aos-init" data-aos="fade-up">
                    <h2>Контакты</h2>
                </div>
                {/*  End Section Title  */}
                <div className="container aos-init" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <div className="info-item aos-init" data-aos="fade" data-aos-delay="200">
                                        <i className="bi bi-geo-alt"></i>
                                        <h3>Адрес</h3>
                                        <p>г. Ижевск</p>
                                        <p>ул. Пойма, 15А</p>
                                    </div>
                                </div>
                                {/*  End Info Item  */}
                                <div className="col-md-6">
                                    <div className="info-item aos-init" data-aos="fade" data-aos-delay="300" style={{height:'100%'}}>
                                        <i className="bi bi-telephone"></i>
                                        <h3>Телефон</h3>
                                        <p><a href="tel:+7 (3412) 998-023">+7 (3412) 998-023</a></p>
                                    </div>
                                </div>
                                {/*  End Info Item  */}
                                <div className="col-md-6">
                                    <div className="info-item aos-init" data-aos="fade" data-aos-delay="400" style={{height:'100%'}}>
                                        <i className="bi bi-envelope"></i>
                                        <h3>Почта</h3>
                                        <p><a href="mailto:sanland-mail-admin@sanland24.ru">sanland-mail-admin@sanland24.ru</a></p>
                                    </div>
                                </div>
                                {/*  End Info Item  */}
                                <div className="col-md-6">
                                    <div className="info-item aos-init" data-aos="fade" data-aos-delay="500">
                                        <i className="bi bi-clock"></i>
                                        <h3>Режим работы</h3>
                                        <p>Понедельник - Пятница</p>
                                        <p>8:00 - 16:30</p>
                                    </div>
                                </div>
                                {/*  End Info Item  */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form action="" method="post" className="email-form aos-init"
                                  data-aos="fade-up" data-aos-delay="200">
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <input type="text" name="name" className="form-control" placeholder="Ваше имя"
                                               required/>
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="email" className="form-control" name="email"
                                               placeholder="Ваша почта" required/>
                                    </div>
                                    <div className="col-12">
                                        <input type="text" className="form-control" name="subject" placeholder="Тема"
                                               required/>
                                    </div>
                                    <div className="col-12">
                                        <textarea className="form-control" name="message" rows={6}
                                                  placeholder="Сообщение" required></textarea>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                        <button type="submit">Отправить</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/*  End Contact Form  */}
                    </div>
                </div>
            </section>
            {/*  /Contact Section  */}
        </div>
    );
}

export default Start;
