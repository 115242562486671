import {CategoryModel} from "../../models/CategoryModel";
import Config from "../util/Config";
import api from "./axios";
import {AxiosError} from "axios";

const fetchCategories = async () => {
    return new Promise<CategoryModel[]>(async (resolve, reject) => {
        try {
            const response = await api.get(Config.api.fetchCategories);
            resolve(response.data.categories.map((el: any) => CategoryModel.fromApi(el)));
        } catch(e) {
            reject((e as AxiosError).status)
            return
        }
    });
};

export default fetchCategories;
