
const Config = {
    urls: {
        main: '/',
        mock: '/mock',
        categories: '/categories',
        profile: '/profile/',
        orders: '/profile/orders',
        addresses: '/profile/addresses',
        clients: '/profile/clients',
    },
    imgPath: '../img/',
    api: {
        register: `${process.env.REACT_APP_API_URL}/user/register`,
        login: `${process.env.REACT_APP_API_URL}/user/login`,
        profile: `${process.env.REACT_APP_API_URL}/user/profile`,
        verifyEmail: `${process.env.REACT_APP_API_URL}/user/verify-email`,
        updateToken: `${process.env.REACT_APP_API_URL}/user/update-token`,
        fetchBrands: `${process.env.REACT_APP_API_URL}/public/brands`,
        fetchCategories: `${process.env.REACT_APP_API_URL}/public/categories`,
        fetchSubCategories: `${process.env.REACT_APP_API_URL}/public/subcategories`,
        fetchProductsByCategory: `${process.env.REACT_APP_API_URL}/public/search`,
        fetchProductsBySearch: `${process.env.REACT_APP_API_URL}/public/search`,
        fetchSaleProducts: `${process.env.REACT_APP_API_URL}/public/sale-products`,
        fetchProduct: `${process.env.REACT_APP_API_URL}/public/product`,
        updateUser: `${process.env.REACT_APP_API_URL}/user/profile`,
        sendEmailVerify: `${process.env.REACT_APP_API_URL}/user/send-email-verify`,
        addLocation: `${process.env.REACT_APP_API_URL}/user/profile/add-location`,
        deleteLocation: `${process.env.REACT_APP_API_URL}/user/profile/delete-location`,
        fetchClients: `${process.env.REACT_APP_API_URL}/admin/clients`,
        toggleC1Confirm: `${process.env.REACT_APP_API_URL}/admin/toggle-confirm`,
        createOrder:`${process.env.REACT_APP_API_URL}/order/create`,
    },
    cdn: 'https://sanland.ams3.cdn.digitaloceanspaces.com',
    cookies: {
        access: {
            expireDuration: 1 / (24 * 4) // 15 minutes
        },
        refresh: {
            expireDuration: 7 // 7 days
        }
    },
    filters: {
        '66b0e550ab1bf0523ef5a104': [
            'Тип',
            'Толщина стенки',
            'Длина',
        ],
        '66b0e5abab1bf0523ef5a105': [
            'Тип',
        ],
        '66b0e5d1ab1bf0523ef5a107': [
            'Намотка трубы'
        ],
        '66b0e5baab1bf0523ef5a106': [
            'Тип',
            'Диаметр',
            'Длина',
            'Толщина',
        ],
        '66b0e674ab1bf0523ef5a10b': [
            'Цвет',
            'Количество секций',
        ],
        '66b0e63aab1bf0523ef5a109': [
            'Количество выходов',
            'Расходомер',
        ],
        '66b0e691ab1bf0523ef5a10c': [
            'Назначение',
            'Длина',
            'Диаметр подключения',
            'Резьба',
        ],
        '66b0e6dfab1bf0523ef5a10e': [
            'Тип',
        ],
        '66b0e6edab1bf0523ef5a10f': [
            'Тип',
            'Цвет',
            'Монтаж',
            'Ширина',
            'Высота',
            'Серия',
        ],
        '66b0e6fcab1bf0523ef5a110': [
            'Цвет',
            'Направление выпуска',
            'Форма',
            'Монтаж',
            'Тип смыва воды',
            'Особенности',
            'Длина крышки сиденья',
        ],
        '66b0e70dab1bf0523ef5a111': [
            'Вращение излива',
            'Длина излива',
            'Цвет',
            'Управление',
            'Монтаж',
            'Подключение фильтра для воды',
            'Тип',
            'Длина шланга',
        ],
        '66b0e64eab1bf0523ef5a10a': [
            'Тип',
            'Резьба',
            'Диаметр резьбы',
        ],
        '66b0e5f2ab1bf0523ef5a108': [
            'Тип',
            'Резьба',
            'Диаметр резьбы',
            'Тип рукоятки'
        ],
        '66b0e6b8ab1bf0523ef5a10d': [
            'Тип',
            'Диаметр',
            'Толщина стенки',
            'Армирование',
            'Резьба',
            'Диаметр резьбы',
            'Диаметр присоединения',
        ],
        '66b0e746ab1bf0523ef5a114': [
            'Тип',
            'Диаметр',
            'Толщина стенки',
            'Армирование',
            'Тип присоединения',
            'Диаметр резьбы',
            'Диаметр присоединения',
        ],
        '66b0e736ab1bf0523ef5a113': [
            'Глубина',
            'Вид раковины',
            'Форма раковины',
            'Цвет',
            'Ширина',
            'Высота',
        ],
        '66b0e76aab1bf0523ef5a116': [
            'Ширина',
            'Монтаж',
            'Количество чаш',
            'Форма',
            'Наличие крыла',
            'Цвет',
        ],
        '66b0e77eab1bf0523ef5a117': [
            'Ширина',
            'Глубина',
            'Высота',
            'Цвет',
            'Управление',
            'Тип',
            'Поверхность кнопки',
        ],
        '66b0e79cab1bf0523ef5a118': [
            'Вид нагрева',
            'Объем бака'
        ],
        '66b0e7aaab1bf0523ef5a119': [
            'Тип',
            'Форма',
            'Вид поддона',
            'Цвет',
            'Ширина',
            'Высота',
            'Монтаж',
        ],
        '66b0e7c6ab1bf0523ef5a11a': [
            'Тип',
            'Назначение',
            'Диаметр выпуска',
            'Направление выпуска',
            'Вид затвора',
            'Длина',
            'Цвет',
        ],
        '66b0e7d6ab1bf0523ef5a11b': [
            'Тип',
            'Длина ванны',
            'Форма ванны',
            'Монтаж',
            'Длина панели',
            'Высота панели',
            'Форма панели',
            'Длина каркаса',
        ],
        '66b0e7e9ab1bf0523ef5a11c': [
            'Тип',
            'Цвет',
            'Монтаж',
        ],
    }
};

export default Config;
