import React, { useState, useEffect, useContext, createContext } from 'react';
import {ProfileModel} from "../../models/ProfileModel";
import {CategoryModel} from "../../models/CategoryModel";
import {SubCategoryModel} from "../../models/SubCategoryModel";
import {BrandModel} from "../../models/BrandModel";
import fetchCategories from "../api/fetchCategories";
import fetchBrands from "../api/fetchBrands";
import fetchSubCategories from "../api/fetchSubCategories";
import fetchAndHandleProfile from "./fetchAndHandleProfile";
import {ProductModel} from "../../models/ProductModel";
import fetchAndHandleClients from "./fetchAndHandleClients";

export interface GlobalState {
    loggedIn: boolean;
    setLoggedIn: (loggedIn: boolean) => void;
    showAuthModal: boolean;
    setShowAuthModal: (showAuthModal: boolean) => void;
    showRetailCost: boolean;
    setShowRetailCost: (showRetailCost: boolean) => void;
    showWholeSaleCost: boolean;
    setShowWholeSaleCost: (showWholeSaleCost: boolean) => void;
    profile: null | ProfileModel;
    setProfile: (profile: null | ProfileModel) => void;


    clients: ProfileModel[];
    setClients: (clients: ProfileModel[]) => void;

    categories: CategoryModel[];
    setCategory: (category: CategoryModel[]) => void;

    subCategories: SubCategoryModel[];
    setSubCategories: (subCategories: SubCategoryModel[]) => void;

    brands: BrandModel[];
    setBrands: (brands: BrandModel[]) => void;

    searchQuery: string;
    setSearchQuery: (query: string) => void;


    loading: boolean;

    cartItems: ProductModel[];
    setCartItems: React.Dispatch<React.SetStateAction<ProductModel[]>>;
    addToCart: (product: ProductModel) => void;
    removeFromCart: (productId: string) => void;
    increaseQuantity: (productId: string) => void;
    decreaseQuantity: (productId: string) => void;
}

interface GlobalProviderProps {
    children: React.ReactNode;
}

const GlobalContext = createContext<GlobalState>({
    loggedIn: false,
    setLoggedIn: () => {},
    showAuthModal:false,
    setShowAuthModal: () => {},
    showRetailCost: true,
    setShowRetailCost: () => {},
    showWholeSaleCost: true,
    setShowWholeSaleCost: () => {},
    profile: null,
    setProfile: () => {},
    clients: [],
    setClients: () => {},
    categories: [],
    setCategory: () => {},
    subCategories: [],
    setSubCategories: () => {},
    brands: [],
    setBrands: () => {},
    loading: true,
    searchQuery: '',
    setSearchQuery: () => {},
    cartItems: [],
    setCartItems:()=>{},
    addToCart:()=>{},
    removeFromCart:()=>{},
    increaseQuantity:()=>{},
    decreaseQuantity:()=>{},
});

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
    const [showRetailCost, setShowRetailCost] = useState<boolean>(true);
    const [showWholeSaleCost, setShowWholeSaleCost] = useState<boolean>(true);
    const [profile, setProfile] = useState<null | ProfileModel>(null);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState<CategoryModel[]>([]);
    const [clients, setClients] = useState<ProfileModel[]>([]);
    const [subCategories, setSubCategories] = useState<SubCategoryModel[]>([]);
    const [brands, setBrands] = useState<BrandModel[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [cartItems, setCartItems] = useState<ProductModel[]>(() => {
        const savedItems = localStorage.getItem('cartItems');
        return savedItems ? JSON.parse(savedItems) : [];
    });

    const handleCategories = async () => {
        return fetchCategories()
            .then((categoriesFetched: CategoryModel[]) => {
                setCategories(categoriesFetched)
            })
            .catch((_: number) => {
                setCategories([]);
            });
    }

    const handleBrands = async () => {
        return fetchBrands()
            .then((brandsFetched: BrandModel[]) => {
                setBrands(brandsFetched);
            })
            .catch((_: number) => {
                setBrands([]);
            })
    }

    const handleSubcategories = async () => {
        return fetchSubCategories()
            .then((subCategoryFetches: SubCategoryModel[]) => {
                setSubCategories(subCategoryFetches);
            })
            .catch((_: number) => {
                setSubCategories([]);
            })
    }
    useEffect(() => {
        const syncCartItems = (event: StorageEvent) => {
            if (event.key === 'cartItems') {
                setCartItems(event.newValue ? JSON.parse(event.newValue) : []);
            }
        };
        window.addEventListener('storage', syncCartItems);
        return () => {
            window.removeEventListener('storage', syncCartItems);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (product: ProductModel) => {
        setCartItems(prevItems => {
            const isProductInCart = prevItems.some(item => item.id === product.id);

            if (!isProductInCart) {
                return [...prevItems, { ...product, amountCart: 1 }];
            }

            return prevItems;
        });
    };

    const removeFromCart = (productId: string) => {
        setCartItems(prevItems => prevItems.filter(item => item.id !== productId));
    };

    const increaseQuantity = (productId: string) => {
        setCartItems(prevItems =>
            prevItems.map(item =>
                item.id === productId ? { ...item, amountCart: item.amountCart + 1 } : item
            )
        );
    };

    const decreaseQuantity = (productId: string) => {
        setCartItems(prevItems =>
            prevItems.map(item =>
                item.id === productId && item.amountCart > 1
                    ? { ...item, amountCart: item.amountCart - 1 }
                    : item
            )
        );
    };

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const value: GlobalState = {
        searchQuery,
        setSearchQuery,
        loggedIn,
        setLoggedIn,
        showAuthModal,
        setShowAuthModal,
        showRetailCost,
        setShowRetailCost,
        showWholeSaleCost,
        setShowWholeSaleCost,
        profile: profile,
        setProfile: setProfile,
        clients,
        setClients,
        categories,
        setCategory: setCategories,
        subCategories,
        setSubCategories,
        brands,
        setBrands,
        loading,
        cartItems,
        setCartItems,
        addToCart,
        removeFromCart,
        increaseQuantity,
        decreaseQuantity,
    }

    useEffect(() => {
        Promise.all([
            fetchAndHandleProfile(setProfile, setLoggedIn),
            handleCategories(),
            handleBrands(),
            handleSubcategories(),
            fetchAndHandleClients(setClients),
        ])
            .then(() => {
                setTimeout(() => setLoading(false), 500);
            });
    }, []);

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
};

export const useGlobalContext = () => useContext(GlobalContext);
