import AuthModalType from "../enums/AuthModalType";
import Config from "../util/Config";
import {NotificationManager} from "react-notifications";
import Cookies from "js-cookie";
import {AuthProps} from "../components/AuthModal";
import login from "../api/login";
import {AuthModel} from "../../models/AuthModel";
import register from "../api/register";
import React, {FormEvent} from "react";

export const switchModal = ({...props}: AuthProps) => {

    const modalContainer = document.querySelector('.auth-modal-container');
    props.setTransition(true);
    if (modalContainer) {
        modalContainer.classList.toggle('auth-modal-container-signup');
    }
    setTimeout(() => {
        props.setTransition(false);
        props.setAuthModalType(props.authModalType === AuthModalType.Login ? AuthModalType.Register : AuthModalType.Login);
        props.setEmail("");
        props.setPassword("");
        props.setConfirmPassword("");
        props.setErrorConfirmPassword("");
        props.setErrorEmail("");
        props.setErrorPassword("");
    }, 310);
};

export const validPassword = ({...props}: AuthProps) => {
    if (props.password === "") {
        props.setErrorPassword("")
    } else if (props.password.length < 8) {
        props.setErrorPassword("Пароль должен быть не менее 8 символов");
    } else if (props.password.length > 30) {
        props.setErrorPassword("Пароль должен быть не более 30 символов");
    } else if (!/[A-Z]/.test(props.password)) {
        props.setErrorPassword("Пароль должен содержать заглавную букву");
    } else if (!/[0-9]/.test(props.password)) {
        props.setErrorPassword("Пароль должен содержать цифру");
    } else {
        props.setErrorPassword("");
    }
};

export const validPasswordConfirm = ({...props}: AuthProps) => {
    if (props.confirmPassword === "") {
        props.setErrorConfirmPassword("");
    } else if (props.password !== props.confirmPassword) {
        props.setErrorConfirmPassword("Пароли не совпадают");
    } else {
        props.setErrorConfirmPassword("");
    }
};

export const validEmail = ({...props}: AuthProps) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (props.email === "") {
        props.setErrorEmail("");
    } else if (!emailRegex.test(props.email)) {
        props.setErrorEmail("Некорректный email");
    } else {
        props.setErrorEmail("");
    }
};

export const loginSubmit = async (e: FormEvent, {...props}: AuthProps) => {
    e.preventDefault();

    const { email, password, errorEmail, errorPassword } = props;

    if (email === "") {
        props.setErrorEmail("Введите email");
        return;
    }
    if (password === "") {
        props.setErrorPassword("Введите пароль");
        return;
    }
    if (errorEmail === "" && errorPassword === "") {
        try {
            const auth = await login(email, password);
            handleSuccess(auth, props);
        } catch {
            handleError(props);
        }
    }
}

export const registerSubmit = async (e: React.MouseEvent, {...props}: AuthProps) => {
    e.preventDefault();

    const { email, password, confirmPassword, errorEmail, errorPassword, errorConfirmPassword } = props;

    if (email === "") {
        props.setErrorEmail("Введите email");
        return;
    }
    if (password === "") {
        props.setErrorPassword("Введите пароль");
        return;
    }
    if (password !== confirmPassword) {
        props.setErrorConfirmPassword("Пароли не совпадают");
        return;
    }
    if (errorEmail === "" && errorPassword === "" && errorConfirmPassword === "") {
        try {
            const auth = await register(email, password);
            Cookies.set('access', auth.tokens.access, {expires: Config.cookies.access.expireDuration});
            Cookies.set('refresh', auth.tokens.refresh, {expires: Config.cookies.refresh.expireDuration});
            handleSuccess(auth, props);
        } catch(e) {
            handleError(props);
        }
    }
}

const handleSuccess = (auth: AuthModel, props: AuthProps) => {
    props.globalContext.setShowAuthModal(false);
    props.globalContext.setLoggedIn(true);
    props.globalContext.setProfile(auth.profile);
    props.navigate(Config.urls.profile);
};

const handleError = (props: AuthProps) => {
    props.globalContext.setLoggedIn(false);
    NotificationManager.error('Ошибка при авторизации', 'Ошибка');
};
