import Config from "../util/Config";
import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer id="footer" className="footer position-relative">

            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-5 col-md-12 footer-about">
                        <a href={Config.urls.main} className="logo d-flex align-items-center">
                            <img className="logo-theme" src={`${Config.cdn}/etc/logo-dark.png`} alt="logo"
                                 style={{margin: "0"}}/>
                        </a>
                        <p>Продажа сантехники и сантехнического оборудования оптом.</p>
                        <p> Работаем только с индивидуальными предпринимателями и юридическими лицами.</p>
                    </div>

                    <div className="col-lg-2 col-6 footer-links redundant">
                        <h4>Информация</h4>
                        <ul>
                            <li><a href={Config.urls.mock}>Web Design</a></li>
                            <li><a href={Config.urls.mock}>Web Development</a></li>
                            <li><a href={Config.urls.mock}>Product Management</a></li>
                            <li><a href={Config.urls.mock}>Marketing</a></li>
                            <li><a href={Config.urls.mock}>Graphic Design</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Навигация</h4>
                        <ul>
                            <li><a href={Config.urls.main}>Главая</a></li>
                            <li><a href={Config.urls.categories}>Категории</a></li>
                            <li><Link to={`${Config.urls.main}#contacts`}>Контакты</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>Контакты</h4>
                        <p>ул. Пойма, 15А</p>
                        <p>г. Ижевск</p>
                        <p className="mt-4"><strong>Телефон:</strong> <span><a href="tel:+7 (3412) 998-023">+7 (3412) 998-023</a></span></p>
                        <p><strong>Почта:</strong> <span><a href="mailto:sanland-mail-admin@sanland24.ru">sanland-mail-admin@sanland24.ru</a></span></p>
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
