import React, {useEffect, useState} from "react";
import setupHeader from "../util/setupHeader";
import Config from "../util/Config";
import {useGlobalContext} from "../util/GlobalProvider";
import Loader from "./Loader";
import updateUser from "../api/updateUser";
import ndsType from "../enums/NdsType";
import sendEmailVerify from "../api/sendEmailVerify";
import {NotificationManager} from "react-notifications";
import fetchProfile from "../api/fetchProfile";
import {ProfileModel} from "../../models/ProfileModel";
import config from "../util/Config";

const Profile = () => {
    // States
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [fatherName, setFatherName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [inn, setInn] = useState<string>("");
    const [nds, setNds] = useState<boolean>(true);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [emailButtonVisible, setEmailButtonVisible] = useState<boolean>(true);
    const [innCorrect, setInnCorrect] = useState<boolean>(false);
    const {profile, setProfile, setLoggedIn} = useGlobalContext();

    // Tools
    const checkForChanges = () => {
        setHasChanges(true);
    }
    const fillInputs = () => {
        if (profile) {
            setFirstName(profile.firstName);
            setLastName(profile.lastName);
            setFatherName(profile.fatherName);
            setPhone(profile.phone);
            setInn(profile.business.inn);
            setNds(profile.business.nds === ndsType.nds);
            setInnCorrect(!!profile?.business?.ogrn);
        }
    }

    // Handlers
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value ?? lastName);
    }
    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value ?? firstName);
    }
    const handleFatherNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFatherName(e.target.value ?? fatherName);
    }
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value ?? phone);
    }
    const handleInnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInn(e.target.value ?? inn);
    }
    const handleNdsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNds(e.target.checked);
    }
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setSubmitted(true);
        try {
            await updateUser({
                firstName: firstName,
                lastName: lastName,
                fatherName: fatherName,
                phone: phone,
                inn: inn,
                nds: nds ? ndsType.nds : ndsType.noNds,
            });
            NotificationManager.success('Информация обновлена!', 'Успех');
        } catch {
            NotificationManager.error('Произошла ошибка при обновлении информации профиля!', 'Ошибка')
        }
        setSubmitted(false);
        try {
            const response = await fetchProfile();
            setProfile(response);
            setLoggedIn(true);
            setInnCorrect(!!response?.business?.ogrn);
        } catch {
            setProfile(null);
            setLoggedIn(false);
        }
    }
    const handleEmailButtonVisibility = () => {
        const lastClickTime = localStorage.getItem('lastEmailVerifyTime');
        if (lastClickTime) {
            const currentTime = Date.now();
            const timeDiff = currentTime - Number(lastClickTime);
            if (timeDiff < 60000) {
                setEmailButtonVisible(false);
                const timeout = setTimeout(() => {
                    setEmailButtonVisible(true);
                }, 60000 - timeDiff);
                return () => clearTimeout(timeout);
            }
        }
    }
    const onEmailVerifyClicked = () => {
        sendEmailVerify()
            .then(() => NotificationManager.success('Пиьсмо отправлено!', 'Успех'))
            .catch(e => NotificationManager.error('Произошла ошибка при отправке письма!', 'Ошибка'));
        localStorage.setItem('lastClickTime', String(Date.now()));
        setEmailButtonVisible(false);
        setTimeout(() => {
            setEmailButtonVisible(true);
        }, 60000);
    }

    // Hooks
    useEffect(handleEmailButtonVisibility, []);
    useEffect(setupHeader, []);
    useEffect(fillInputs, [profile]);
    useEffect(checkForChanges, [firstName, lastName, fatherName, inn, nds]);

    return (
        <div className="profile">
            <div className="profile-div">
                <span className="profile-component-title">Профиль</span>

                {profile ? (
                    <React.Fragment>
                        {!profile.emailConfirmed && emailButtonVisible &&
                            <div className="profile-component-notification">
                                <img src={`${Config.cdn}/icons/error.svg`} alt="error"/>
                                <span>Подтвердите свою почту по ссылке в письме {profile.email}</span>
                                <button onClick={onEmailVerifyClicked} className="email-repeat-btn">Отправить ещё раз
                                </button>
                            </div>
                        }
                        <div>
                            <div className="smart-block">
                                <div className="smart-inputs">
                                    <div className="smart-input">
                                        <input type="text" placeholder=" " id="surname" value={lastName}
                                               onChange={(e) => handleLastNameChange(e)}/>
                                        <label htmlFor="name" className="label">Фамилия</label>
                                    </div>
                                    <div className="smart-input">
                                        <input type="text" placeholder=" " id="name" value={firstName}
                                               onChange={(e) => handleFirstNameChange(e)}/>
                                        <label htmlFor="name" className="label">Имя</label>
                                    </div>
                                    <div className="smart-input">
                                        <input type="text" placeholder=" " id="fathername" value={fatherName}
                                               onChange={(e) => handleFatherNameChange(e)}/>
                                        <label htmlFor="name" className="label">Отчество</label>
                                    </div>
                                    <div className="smart-input">
                                        <input type="phone" placeholder=" " id="phone" value={phone}
                                               onChange={(e) => handlePhoneChange(e)}/>
                                        <label htmlFor="name" className="label">Номер телефона</label>
                                    </div>
                                    <div className="smart-input">
                                        <input type="text" placeholder=" " id="inn" value={inn}
                                               onChange={(e) => handleInnChange(e)}/>
                                        <label htmlFor="name" className="label">ИНН</label>
                                    </div>
                                    <label className={`profile-input-nds ${nds ? 'checked' : ''}`}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={nds}
                                            onChange={(e) => handleNdsChange(e)}
                                        />
                                        <span className="checkbox-label">НДС</span> {/* Добавляем span для текста */}
                                    </label>
                                </div>
                                <div className="company-info">
                                    {innCorrect && (
                                        <>
                                            <h3>
                                            <img className="icon" src={`${config.cdn}/icons/lock.svg`} alt="lock"/>
                                                Информация по ИНН
                                            </h3>
                                            <p className="info-item">
                                                <span className="icon">🏢</span>
                                                <strong>ОГРН:</strong>
                                                <span className="value">{profile.business.ogrn}</span>
                                            </p>
                                            <p className="info-item">
                                                <span className="icon">📝</span>
                                                <strong>Наименование:</strong>
                                                <span className="value">{profile.business.name}</span>
                                            </p>
                                            {profile.business.kpp && (
                                                <p className="info-item">
                                                    <span className="icon">🏛️</span>
                                                    <strong>КПП:</strong>
                                                    <span className="value">{profile.business.kpp}</span>
                                                </p>
                                            )}
                                            <p className="info-item">
                                                <span className="icon">📍</span>
                                                <strong>Адрес:</strong>
                                                <span className="value">{profile.business.legalAddress}</span>
                                            </p>
                                            {profile.business.type && (
                                                <p className="info-item">
                                                    <span className="icon">🏷️</span>
                                                    <strong>Тип:</strong>
                                                    <span
                                                        className="value">{profile.business.type === 'ooo' ? 'ООО' : 'ИП'}</span>
                                                </p>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr/>
                        {submitted && <Loader/>}
                        {!submitted &&
                            <button onClick={handleSubmit} disabled={!hasChanges} className="profile-btn">Обновить
                                данные</button>}
                    </React.Fragment>) : (
                    <Loader/>
                )}
            </div>
        </div>
    );
};

export default Profile;
