import React, {useEffect, useMemo, useState} from 'react';
import Modal from 'react-modal';
import Config from "../util/Config";
import {CSSTransition} from 'react-transition-group';
import {NavigateFunction, useNavigate} from "react-router-dom";
import {GlobalState, useGlobalContext} from "../util/GlobalProvider";
import AuthModalType from "../enums/AuthModalType";
import LoginView from "./LoginView";
import RegisterView from "./RegisterView";
import {validEmail, validPassword, validPasswordConfirm} from "../funcs/AuthModal";

export interface AuthProps {
    globalContext: GlobalState;
    navigate: NavigateFunction;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    confirmPassword: string;
    setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
    transition: boolean;
    setTransition: React.Dispatch<React.SetStateAction<boolean>>;
    showPassword: boolean;
    setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
    authModalType: AuthModalType;
    setAuthModalType: React.Dispatch<React.SetStateAction<AuthModalType>>;
    errorEmail: string;
    setErrorEmail: React.Dispatch<React.SetStateAction<string>>;
    errorPassword: string;
    setErrorPassword: React.Dispatch<React.SetStateAction<string>>;
    errorConfirmPassword: string;
    setErrorConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
}

const AuthModal = () => {
    Modal.setAppElement('#root');
    const navigate = useNavigate();
    const globalContext = useGlobalContext();

    const [errorEmail, setErrorEmail] = useState<string>("");
    const [errorPassword, setErrorPassword] = useState<string>("");
    const [errorConfirmPassword, setErrorConfirmPassword] = useState<string>("");
    const {showAuthModal, setShowAuthModal} = useGlobalContext();
    const [authModalType, setAuthModalType] = useState<AuthModalType>(AuthModalType.Login);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [transition, setTransition] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const authModalProps = useMemo(() => ({
        globalContext, navigate, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, transition, setTransition, showPassword, setShowPassword, authModalType, setAuthModalType,  errorEmail, setErrorEmail, errorPassword, setErrorPassword, errorConfirmPassword, setErrorConfirmPassword,
    }), [globalContext, navigate, email, password, confirmPassword, transition, showPassword, authModalType, errorEmail, errorPassword, errorConfirmPassword]);

    useEffect(() => {
        validPassword(authModalProps);
        validPasswordConfirm(authModalProps);
        validEmail(authModalProps);
    }, [authModalProps]);

    return (
        <>
            {globalContext.loggedIn ? (
                <button onClick={() => navigate(Config.urls.profile)} className="btn-getstarted">Профиль</button>
            ) : (
                <button onClick={() => setShowAuthModal(true)} className="btn-getstarted">Войти</button>
            )}

            <Modal
                isOpen={showAuthModal}
                onRequestClose={() => setShowAuthModal(false)}
                contentLabel="Авторизация"
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className={`modal-container auth-modal-container${authModalType ? '-signup' : ''}`}>
                    <CSSTransition
                        in={!transition && authModalType === AuthModalType.Login}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <LoginView {...authModalProps} />
                    </CSSTransition>
                    <CSSTransition
                        in={!transition && authModalType === AuthModalType.Register}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <RegisterView {...authModalProps} />
                    </CSSTransition>
                </div>
            </Modal>
        </>
    );
};

export default AuthModal;