const formatPhoneNumber = (phoneNumber: string): string => {
    // Проверяем, что входная строка имеет правильный формат
    const cleaned = phoneNumber.replace(/\D/g, ''); // Удаляем все нецифровые символы

    // Используем регулярное выражение для захвата частей номера
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
        const intlCode = match[1];  // +7
        const areaCode = match[2];  // 952
        const firstPart = match[3]; // 453
        const secondPart = match[4]; // 65
        const thirdPart = match[5]; // 93

        // Возвращаем отформатированную строку
        return `+${intlCode} (${areaCode}) ${firstPart}-${secondPart}-${thirdPart}`;
    }

    // Если входная строка не соответствует формату, возвращаем оригинальный номер
    return phoneNumber;
}

export default formatPhoneNumber;
