import onStartPageLoaded from "../util/onStartPageLoaded";
import CategoryView from "../components/CategoryView";
import Path from "../components/Path"
import React, {useEffect} from 'react';
import { useSearchParams} from 'react-router-dom';
import {useGlobalContext} from "../util/GlobalProvider";
import { useNavigate } from 'react-router-dom';
import Config from "../util/Config";
import {brandById} from "../util/brandById";
import setupHeader from "../util/setupHeader";


const CategoriesPage = () => {
    const [searchParams] = useSearchParams();
    const brandId = searchParams.get('brandId');
    const {categories, brands} = useGlobalContext();
    const navigate = useNavigate();

    useEffect(() => {
        if ( brandId && brands && !brandById(brands, brandId)) {
            navigate(Config.urls.main);
        }
    }, [brandId, brands, navigate]);

    useEffect(() => {
        onStartPageLoaded()
        setupHeader()
    });
    return (
        <>
            <Path brandId={brandId} categoryId={null}/>
            <div className="catalog">

                <h4 className="title-categories">{brandId ? `Категории товаров от ${brandById(brands,brandId)?.name}` : 'Категории товаров'}</h4>
                <div className="categories">
                    {
                        categories
                            ?.map(category => (
                                brandId ?
                                    <CategoryView key={category.id} category={category} brandId={brandId}/> :
                                    <CategoryView key={category.id} category={category}/>
                            ))
                    }
                </div>
            </div>
        </>
    );
};

export default CategoriesPage;
