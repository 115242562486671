import onStartPageLoaded from "../util/onStartPageLoaded";
import ProductCards from "../components/ProductCards";
import Path from "../components/Path"
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Config from "../util/Config";
import Filters from "../components/Filters";
import ProductCardViewType from "../enums/ProductCardViewType";
import {SubCategoryModel} from "../../models/SubCategoryModel";
import {BrandModel} from "../../models/BrandModel";
import {categoryById} from "../util/categoryById";
import {useGlobalContext} from "../util/GlobalProvider";
import {brandById} from "../util/brandById";
import setupHeader from "../util/setupHeader";
import FetchProductsByCategoryInterface from "../../models/FetchProductsByCategoryInterface";
import ProductSortType from "../enums/ProductSortType";
import useLoadProducts from "../hooks/LoadProducts";
import Loader from "../components/Loader";
import ActiveFilters from "../components/ActiveFilters";


const CatalogPage = () => {
    const {categories, brands, searchQuery, setSearchQuery} = useGlobalContext();
    const pathParams = useParams();
    const categoryId = pathParams.categoryId ?? null;
    const category = categoryById(categories, categoryId!)!;
    const navigate = useNavigate();
    const location = useLocation();
    const [viewType, setViewType] = useState<ProductCardViewType>(() => {
        const savedViewType = localStorage.getItem('viewType');
        if (savedViewType && Object.values(ProductCardViewType).includes(savedViewType as unknown as ProductCardViewType)) {
            return savedViewType as unknown as ProductCardViewType;
        }
        return ProductCardViewType.grid; // Значение по умолчанию
    });
    const [sortType, setSortType] = useState<ProductSortType>(() => {
        const savedSortType = localStorage.getItem('sortType');
        return savedSortType ? (savedSortType as ProductSortType) : ProductSortType.alphabet;
    });
    const [sortDirection, setSortDirection] = useState<1 | -1>(() => {
        const savedSortDirection = localStorage.getItem('sortDirection');
        return savedSortDirection ? (parseInt(savedSortDirection) as 1 | -1) : 1;
    });
    const [inStock, setInStock] = useState<boolean>(() => {
        const savedInStock = localStorage.getItem('inStock');
        return savedInStock ? JSON.parse(savedInStock) : false;
    });
    useEffect(() => {
        localStorage.setItem('viewType', viewType.toString());
    }, [viewType]);
    useEffect(() => {
        localStorage.setItem('sortType', sortType);
    }, [sortType]);
    useEffect(() => {
        localStorage.setItem('sortDirection', sortDirection.toString());
    }, [sortDirection]);
    useEffect(() => {
        localStorage.setItem('inStock', JSON.stringify(inStock));
    }, [inStock]);

    const [costRange, setCostRange] = useState<number[]>([0, 100000]);
    const [minCost, setMinCost] = useState<number>(0);
    const [maxCost, setMaxCost] = useState<number>(100000);
    const [selectedSubCategories, setSelectedSubCategories] = useState<SubCategoryModel[]>([]);
    const [selectedBrands, setSelectedBrands] = useState<BrandModel[]>([]);
    const [filterArray, setFilterArray] = useState<string[]>([]);
    const [query, setQuery] = useState<string>('');
    useEffect(() => {
        if (categoryId && categories && !brandById(categories, categoryId)) {
            navigate(Config.urls.main);
        }
    }, [categoryId, categories, navigate]);


    const filters = useMemo(() => ({
        category,
        filterArray,
        setFilterArray,
        inStock,
        setInStock,
        costRange,
        setCostRange,
        minCost,
        setMinCost,
        maxCost,
        setMaxCost,
        searchQuery,
        setSearchQuery,
        selectedSubCategories,
        setSelectedSubCategories,
        selectedBrands,
        setSelectedBrands,
    }), [inStock, setInStock, costRange, setCostRange, minCost, setMinCost, maxCost, setMaxCost, searchQuery, setSearchQuery, selectedSubCategories, setSelectedSubCategories, selectedBrands, setSelectedBrands, filterArray, setFilterArray]);

    useEffect(() => {
        const filterValues = filterArray
            .map(filter => filter.split(':')[1])
            .filter(Boolean)
            .join(' ');

        setQuery(`${searchQuery} ${filterValues}`.trim());
    }, [searchQuery, filterArray]);

    const productFilters: FetchProductsByCategoryInterface = useMemo(() => ({
        subCategoryIds: selectedSubCategories.map(sc => sc.id),
        brandIds: selectedBrands.map(brand => brand.id),
        costMin: minCost,
        costMax: maxCost,
        stock: inStock,
        sort: sortType,
        sortDirection: sortDirection,
        query: query,
        cursor: {
            cost: 0,
            score: null,
            name: '',
            id: ''
        }
    }), [selectedSubCategories, selectedBrands, minCost, maxCost, inStock, sortType, sortDirection, query]);

    const toggleSortDirection = () => {
        setSortDirection(prevDirection => (prevDirection === 1 ? -1 : 1)); // Переключаем направление сортировки
    };
    const handleSortTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortType(event.target.value as ProductSortType);
    };
    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const updateUrlWithFilters = () => {
        const params = new URLSearchParams();
        if (filters.selectedBrands.length > 0) {
            let brandIds = filters.selectedBrands.map(brand => brand.id);
            params.set('brands', brandIds.join(','));
        }
        if (filters.searchQuery != "") {
            let searchQuery = filters.searchQuery;
            params.set('q', searchQuery);
        }
        navigate(`${Config.urls.categories}/${filters.category?.id}?${params.toString()}`);
    };

    useEffect(updateUrlWithFilters, [filters.selectedBrands, filters.searchQuery]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const brandsFromUrl = params.get('brands');
        if (brandsFromUrl) {
            filters.setSelectedBrands(brandsFromUrl.split(',').map(brandId => brandById(brands, brandId)!));
        }
        const queryParams = params.get('q');
        if (queryParams) {
            filters.setSearchQuery(queryParams);
        }
    }, []);

    const memoizedParams = useMemo(() => ({categoryId, productFilters}), [categoryId, productFilters]);
    const {products, error, loading} = useLoadProducts(memoizedParams);

    useEffect(() => {
        onStartPageLoaded()
        setupHeader()
    });
    return (
        <>
            <Path brandId={null} categoryId={categoryId}/>
            <div className="catalog">
                <div className="catalog-page">
                    {filters && <Filters {...filters}/>}
                    <div className="products-all">
                        <div className="search-sort">
                            <input type="text" name="product" className="search-product form-control"
                                   placeholder="Поиск"
                                   value={searchQuery}
                                   onChange={handleSearchTextChange}></input>
                            <div className="sort">
                                <span>Сортировать по</span>
                                <select
                                    name="sort"
                                    className="form-control"
                                    value={sortType}
                                    onChange={handleSortTypeChange}
                                >
                                    <option value={ProductSortType.alphabet}>По алфавиту</option>
                                    <option value={ProductSortType.price}>По цене</option>
                                </select>
                                <button onClick={toggleSortDirection} className="sort-direction-btn">
                                    <img
                                        src={`${Config.cdn}/icons/chevron.right.svg`}
                                        alt="chevron"
                                        className={sortDirection === 1 ? 'rotate-asc' : 'rotate-desc'}
                                    />
                                </button>

                                <img src={`${Config.cdn}/icons/rectangle.grid.2x2.svg`} alt="grid"
                                     onClick={() => setViewType(ProductCardViewType.grid)}
                                     className={(viewType === ProductCardViewType.grid ? 'grid active-btn-group' : 'grid')}/>
                                <img src={`${Config.cdn}/icons/rectangle.grid.1x2.svg`} alt="list"
                                     onClick={() => setViewType(ProductCardViewType.list)}
                                     className={(viewType === ProductCardViewType.list ? 'list active-btn-group' : 'list')}/>
                            </div>
                        </div>
                        <ActiveFilters searchQuery={searchQuery}
                                       setSearchQuery={setSearchQuery}
                                       setInStock={setInStock}
                                       setSelectedSubCategories={setSelectedSubCategories}
                                       setSelectedBrands={setSelectedBrands}
                                       setMaxCost={setMaxCost}
                                       setMinCost={setMinCost}
                                       setCostRange={setCostRange}
                                       minCost={minCost}
                                       maxCost={maxCost}
                                       inStock={inStock}
                                       selectedSubCategories={selectedSubCategories}
                                       selectedBrands={selectedBrands}
                                       filterArray={filterArray}
                                       setFilterArray={setFilterArray}/>
                        <div className="products-container">
                            {categoryId && <ProductCards viewType={ProductCardViewType.grid}
                                                         showState={viewType === ProductCardViewType.grid}
                                                         categoryId={categoryId}
                                                         products={products}
                                                         error={error}/>
                            }
                            {categoryId && <ProductCards viewType={ProductCardViewType.list}
                                                         showState={viewType === ProductCardViewType.list}
                                                         categoryId={categoryId}
                                                         products={products}
                                                         error={error}/>
                            }
                            {loading && <Loader/>}
                            {!loading && !error && products.length === 0 && <div>
                                <div className="no-products-div">
                                    <span style={{fontSize: "1rem"}}>Нет подходящих товаров</span>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CatalogPage;
