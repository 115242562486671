import {ProductModel} from "../../models/ProductModel";

export const handleInputChange = (productId: string, newValue: string, setCartItems: React.Dispatch<React.SetStateAction<ProductModel[]>>) => {
    const newAmount = Number(newValue);

    if (!isNaN(newAmount) && newAmount > 0) {
        const cartItems: ProductModel[] = JSON.parse(localStorage.getItem('cartItems') || '[]');

        const updatedItems = cartItems.map(item =>
            item.id === productId
                ? { ...item, amountCart: newAmount }
                : item
        );

        localStorage.setItem('cartItems', JSON.stringify(updatedItems));
        setCartItems(updatedItems); // обновляем состояние компонента
    }
};
