import React from "react";
import Config from "../util/Config";
import {CategoryModel} from "../../models/CategoryModel";
import {SubCategoryModel} from "../../models/SubCategoryModel";

interface CategoryMiniCardViewProps {
    category: CategoryModel;
    subCategories: SubCategoryModel[];
}

const CategoryMiniCardView: React.FC<CategoryMiniCardViewProps> = ({ category, subCategories }) => {
    return(
        <a data-aos="fade-up" href={`${Config.urls.categories}/${category.id}`} className="category-start aos-init">
            <div className="category-start-img-background">
                <img src={category.imageUri} alt="Faucet SVG" className="category-start-img"/>
            </div>
            <div className="category-start-name">
                <h5 style={{margin: '0'}}>{category.name}</h5>
                <span className="category-start-discription">{category.joinDescription(subCategories)}</span>
            </div>
        </a>
    );
}

export default CategoryMiniCardView;
