import fetchProductsByCategory from "../api/fetchProductsByCategory";
import {ProductModel} from "../../models/ProductModel";
import ProductCardViewType from "../enums/ProductCardViewType";
import FetchProductsByCategoryInterface from "../../models/FetchProductsByCategoryInterface";

export const loadProducts = async (
    categoryId: string | null,
    filters: FetchProductsByCategoryInterface,
    cursor: { id: string; name: string; cost: number; score: number | null } | null,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setProducts: React.Dispatch<React.SetStateAction<ProductModel[]>>,
    setError: React.Dispatch<React.SetStateAction<Error | null>>,
    setHasMore: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setLoading(true);
    setError(null);

    try {
        const newFilters = { ...filters };

        if (cursor) {
            newFilters.cursor = {
                id: cursor.id,
                name: cursor.name,
                cost: cursor.cost,
                score: cursor.score,
            };
        }

        const fetchedProducts = await fetchProductsByCategory(categoryId, newFilters);
        if (fetchedProducts.length === 0 || fetchedProducts.length<50) {
            setHasMore(false);
        }

        const productModels = fetchedProducts.map((product: any) => {
            if (product.amountCart === undefined) {
                product.amountCart = 0;
            }
            return ProductModel.fromApi(product);
        });

        setProducts((prevProducts) => {
            const newUniqueProducts = productModels.filter((newProduct: ProductModel) =>
                !prevProducts.some((product: ProductModel) => product.id === newProduct.id)
            );
            return [...prevProducts, ...newUniqueProducts];
        });

    } catch (e) {
        console.error('Error loading products:', e);
        setError(new Error('Failed to load products'));
    } finally {
        setLoading(false);
    }
};

export const toggleShowUI = (showState: boolean, viewType: ProductCardViewType) => {
    const content = document.getElementById(`products-${viewType}`);
    if (!content) return;

    if (showState) {
        content.removeAttribute('hidden');
        setTimeout(() => {
            content.classList.add('products-visible');
            content.classList.remove('products-hidden');
        }, 150);
    } else {
        content.classList.remove('products-visible');
        content.classList.add('products-hidden');
        setTimeout(() => content.setAttribute('hidden', ''), 150);
    }
}
