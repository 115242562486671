import React, {useEffect} from 'react';
import ProfileNavigation from "../components/ProfileNavigation";
import {Outlet, useLocation, useRoutes} from "react-router-dom";
import Addresses from "../components/Addresses";
import Orders from "../components/Orders";
import Profile from "../components/Profile";
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import setupHeader from "../util/setupHeader";
import {useGlobalContext} from "../util/GlobalProvider";
import Clients from "../components/Clients";
import ClientRole from "../enums/ClientRole";

const ProfilePage = () => {
    const location = useLocation();
    const {profile, clients} = useGlobalContext();

    useEffect(() => {
        setupHeader();
    }, []);

    let routes = useRoutes([
        {
            path: "/",
            element: <div><Profile/> <Outlet/></div>
        },
        {
            path: "orders",
            element: profile ? <div><Orders/> <Outlet/></div> : null
        },
        {
            path: "addresses",
            element: profile ? <div><Addresses locations={profile?.business.locations}/> <Outlet/></div> : null
        },
        {
            path: "clients",
            element: profile && profile.role === ClientRole.admin ? <div><Clients clients={clients}/> <Outlet/></div> : null
        },
    ]);

    return (
        <div className="profile-page">
            <div className="profile-container">
                {profile && <ProfileNavigation/>}
                <SwitchTransition>
                    <CSSTransition key={location.key} classNames="fade-profile" timeout={300}>
                        {routes}
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    );
};

export default ProfilePage;
