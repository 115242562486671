import config from "../util/Config";
import api from "./axios";
import Cookies from "js-cookie";
import {AxiosError} from "axios";


const sendEmailVerify = async () => {
    try {
        await api.post(config.api.sendEmailVerify, {}, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access')}`
            }
        });
    } catch (e) {
        console.log(e);
        let response = (e as AxiosError).response
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default sendEmailVerify
