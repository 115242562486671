import {SearchGroupItemModel} from "../../models/SearchGroupItem";
import config from "../util/Config";
import React from "react";
import getCorrectProductWord from "../util/getCorrectProductWord";
import {Link} from "react-router-dom";

interface SearchDropdownProps {
    items: SearchGroupItemModel[];
    query: string;
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({items, query}) => {
    return (
        <>
            <div className="search-dropdown">
                <div className="search-dropdown-scrollable">
                    {items.map((item: SearchGroupItemModel, index: number) => {
                        return (
                            <div className="search-dropdown-item-capsule">
                                <Link className="search-dropdown-item"
                                   to={`${config.urls.categories}/${item.categoryId}?q=${query}`}>
                                    <div className="search-dropdown-item-icon">
                                        <img src={item.category?.imageUri} alt="icon"/>
                                    </div>
                                    <div className="search-dropdown-item-text">
                                        <span className="search-dropdown-item-title">{item.category?.name}</span>
                                        <span
                                            className="search-dropdown-item-description">{getCorrectProductWord(item.count)}</span>
                                    </div>
                                    <div className="search-dropdown-item-arrow">
                                        <img src={`${config.cdn}/icons/chevron.right.svg`} alt="arrow"/>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default SearchDropdown;
