import {CategoryModel} from "./CategoryModel";
import {categoryById} from "../js/util/categoryById";

export interface SearchGroupItemInterface {
    categoryId: string;
    count: number;
}

export class SearchGroupItemModel {
    categoryId: string;
    count: number;
    category: CategoryModel | null;

    constructor(data: SearchGroupItemInterface, categories: CategoryModel[]) {
        this.categoryId = data.categoryId;
        this.count = data.count;
        this.category = categoryById(categories, data.categoryId);
    }

    static fromApi(data: any, categories: CategoryModel[]): SearchGroupItemModel {
        return new SearchGroupItemModel({
            categoryId: data.category_id,
            count: data.count,
        }, categories);
    }
}
