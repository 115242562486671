import React from "react";
import Config from "../util/Config";
import {BrandModel} from "../../models/BrandModel";

interface BrandCardProps {
    brand: BrandModel;
}

const BrandCard: React.FC<BrandCardProps> = ({ brand }) => {
    return (
        <>
        {!brand.imageUri ? '' :
            <a href={`${Config.urls.categories}?brandId=${brand.id}`} className="brand-logo">
                <img src={brand.imageUri} alt=""/>
            </a>
        }
        </>
    );
}

export default BrandCard;
