import api from "./axios";
import config from "../util/Config";
import {AxiosError} from "axios";

const fetchProduct = async (productId: string) => {
    try {

        const response = await api.get(
            `${config.api.fetchProduct}/${productId}`,
        );

        return response.data;
    } catch (e) {
        let response = (e as AxiosError).response;
        throw new Error(`${response?.status} ${JSON.stringify(response?.data)}`);
    }
}

export default fetchProduct;