import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Config from '../util/Config';
import PrivateRoute from "../util/PrivateRoute";

import Header from "../components/Header";
import Footer from "../components/Footer";

import StartPage from "./StartPage";
import CategoriesPage from './CategoriesPage';
import CatalogPage from './CatalogPage';
import ProfilePage from "./ProfilePage";
import ProductPage from "./ProductPage";

import ScrollTopButton from "../components/ScrollTopButton";
import onStartPageLoaded from "../util/onStartPageLoaded";
import Loading from "../components/Loading";
import {useGlobalContext} from "../util/GlobalProvider";
import {NotificationContainer} from "react-notifications";

import '../../css'

const MainPage = () => {
    const {loading} = useGlobalContext();

    useEffect(() => {
        onStartPageLoaded();
    }, []);

    return (
        <div className="scroll-wrapper">
            <Loading/>
            <Router>
                <Header/>

                <NotificationContainer className="custom-notification-container"/>
                {loading ? '' : <Routes>
                    {/* Главная */}
                    <Route path={Config.urls.main} element={<StartPage/>}/>
                    {/* Категории */}
                    <Route path={Config.urls.categories} element={<CategoriesPage/>}/>
                    {/* Каталог выбранной категории */}
                    <Route path={`${Config.urls.categories}/:categoryId`} element={<CatalogPage/>}/>
                    {/* Страница товара */}
                    <Route path={`${Config.urls.categories}/:categoryId/:productId`} element={<ProductPage/>}/>
                    {/* (Приватно) Профиль */}
                    <Route path={Config.urls.profile + '/*'} element={<PrivateRoute/>}>
                        <Route path="*" element={<ProfilePage/>}/>
                    </Route>
                </Routes>}
                <Footer/>
                <ScrollTopButton/>
            </Router>
        </div>
    );
};

export default MainPage;
