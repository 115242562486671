import {SubCategoryModel} from "../../models/SubCategoryModel";
import Config from "../util/Config";
import api from "./axios";
import {AxiosError} from "axios";

const fetchSubCategories = async () => {
    return new Promise<SubCategoryModel[]>(async (resolve, reject) => {
        try {
            const response = await api.get(Config.api.fetchSubCategories);
            resolve(response.data.sub_categories.map((el: any) => SubCategoryModel.fromApi(el)));
        } catch(e) {
            reject((e as AxiosError).status)
            return
        }
    });
};

export default fetchSubCategories;
