import React, {useEffect} from "react";
import onStartPageLoaded from "../util/onStartPageLoaded";



const Orders = () => {
    useEffect(() => { onStartPageLoaded() });
    return (
        <div className="orders">
            <div className="orders-div">
                <span className="orders-component-title">Заказы</span>
                <div className="no-orders-div">
                    У вас пока нет заказов<br/>
                    Добавьте товары в корзину и оформите заказ
                </div>
            </div>

        </div>
    );
};

export default Orders;