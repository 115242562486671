import Config from "../util/Config";
import React from "react";
import AuthModal from "./AuthModal";
import Cart from "./Cart";

const Header = () => {

    return (
        <header id="header" className="header d-flex align-items-center fixed-top">
            <div className="container-fluid position-relative d-flex align-items-center justify-content-between">
                <a href={Config.urls.main} className="logo d-flex align-items-center me-auto me-xl-0">
                    <div className="main-logo-container">
                        <img className="logo-theme" src={`${Config.cdn}/etc/logo-dark.png`} alt="logo" />
                    </div>
                </a>

                <nav id="navmenu" className="navmenu">
                    <ul>
                        <li><a href={Config.urls.main} className="header-paths">Главная</a></li>
                        <li><a href={Config.urls.categories} className="header-paths">Каталог</a></li>
                        <li><a href="/#contact" className="header-paths">Контакты</a></li>
                    </ul>
                    <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
                </nav>
                <Cart/>
                <AuthModal />
            </div>
        </header>
    );
};

export default Header;