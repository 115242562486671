import Config from "../util/Config";
import React from "react";

const ScrollTopButton = () => {
    return (
        <a href={Config.urls.mock} id="scroll-top"
           className="scroll-top d-flex align-items-center justify-content-center"><i
            className="bi bi-arrow-up-short"></i></a>
    );
}

export default ScrollTopButton;
