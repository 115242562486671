import React, {CSSProperties} from "react";

interface LoaderProps {
    className?: string;
}

const Loader: React.FC<LoaderProps> = ({className}) => {
    return <div className="loader-container"><span className={className ? className : 'loader'}></span></div>
}

export default Loader;
