import React, {useEffect} from "react";
import ProductCard from "./ProductCard";
import ProductCardViewType from "../enums/ProductCardViewType";
import { toggleShowUI} from "../hooks/ProductCards";
import {ProductModel} from "../../models/ProductModel";

interface ProductCardsProps {
    viewType: ProductCardViewType;
    showState: boolean;
    categoryId: string;
    products: ProductModel[]
    error: Error | null;
}

const ProductCards: React.FC<ProductCardsProps> = ({viewType, showState, categoryId, products ,error}) => {
    const viewTypeClass = viewType === ProductCardViewType.grid ? 'grid-view' : 'list-view';

    useEffect(() => {
        toggleShowUI(showState, viewType);
    }, [showState, viewType])

    return (
        <>
            <div id={`products-${viewType}`} className={`products ${viewTypeClass}`}>
                {error && <div>Error: {error.message}</div>}
                {Array.isArray(products) && products.map(product => (
                    <ProductCard key={product.id} product={product} view={viewType} categoryId={categoryId}/>
                ))}
            </div>
        </>
    );
}

export default ProductCards;
