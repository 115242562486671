import Config from "../util/Config";
import React from "react";
import {AuthProps} from "./AuthModal";
import {registerSubmit, switchModal} from "../funcs/AuthModal";
import {useGlobalContext} from "../util/GlobalProvider";

const RegisterView: React.FC<AuthProps> = ({ ...props }) => {
    const { setShowAuthModal} = useGlobalContext();

    return (
        <>
            <div className="auth-div-form">
                <button onClick={() => setShowAuthModal(false)} className="close-modal-btn">×</button>
                <h5>Регистрация</h5>
                <form className="auth-form">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={props.email}
                        onChange={e => props.setEmail(e.target.value)}
                        required
                        className={`form-control auth-form-input ${props.errorEmail ? 'error' : ''}`}
                        placeholder="Почта"
                    />
                    <label className="auth-error-span">{props.errorEmail}</label>
                    <label>Пароль:</label>
                    <div className="input-container">
                        <input
                            type={props.showPassword ? 'text' : 'password'}
                            value={props.password}
                            onChange={e => props.setPassword(e.target.value)}
                            required
                            className={`form-control auth-form-input password-input ${props.errorPassword ? 'error' : ''}`}
                            placeholder="Пароль"
                        />
                        <img
                            className="toggle-img"
                            src={props.showPassword ? `${Config.cdn}/icons/eye.svg` : `${Config.cdn}/icons/eye.slash.svg`}
                            onMouseDown={() => props.setShowPassword(true)}
                            onMouseUp={() => props.setShowPassword(false)}
                            onMouseLeave={() => props.setShowPassword(false)}
                            alt="Toggle visibility"
                        />
                    </div>
                    <label className="auth-error-span">{props.errorPassword}</label>
                    <label>Подтвердите пароль:</label>
                    <div className="input-container">
                        <input
                            type={props.showPassword ? 'text' : 'password'}
                            value={props.confirmPassword}
                            onChange={e => props.setConfirmPassword(e.target.value)}
                            required
                            className={`form-control auth-form-input ${props.errorConfirmPassword ? 'error' : ''}`}
                            placeholder="Подтверждение пароля"
                        />
                        <img
                            className="toggle-img"
                            src={props.showPassword ? `${Config.cdn}/icons/eye.svg` : `${Config.cdn}/icons/eye.slash.svg`}
                            onMouseDown={() => props.setShowPassword(true)}
                            onMouseUp={() => props.setShowPassword(false)}
                            onMouseLeave={() => props.setShowPassword(false)}
                            alt="Toggle visibility"
                        />
                    </div>
                    <label className="auth-error-span">{props.errorConfirmPassword}</label>
                    <button
                        type="submit"
                        onClick={(e) => registerSubmit(e, props)}
                        className="auth-btn"
                    >
                        Зарегистрироваться
                    </button>
                </form>
                <p>Уже есть аккаунт? <span onClick={() => switchModal(props)} className="auth-switcher">Войти</span></p>
            </div>
        </>
    );
}

export default RegisterView;
