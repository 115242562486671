import Config from "../js/util/Config";
import {SubCategoryModel} from "./SubCategoryModel";

export interface CategoryInterface {
    id: string;
    name: string;
    imageUri: string;
}

export class CategoryModel {
    id: string;
    name: string;
    imageUri: string;

    constructor(data: CategoryInterface) {
        this.id = data.id;
        this.name = data.name;
        this.imageUri = data.imageUri;
    }

    joinDescription(subCategories: SubCategoryModel[]): string {
        return subCategories
            .filter((subCategory: SubCategoryModel) => subCategory.categoryId === this.id)
            .map((s) => s.name)
            .join(", ")
    }

    static fromApi(data: any): CategoryModel {
        return new CategoryModel({
            id: data.id,
            name: data.name,
            imageUri: `${Config.cdn}/categories/${data.image_id}.png`,
        });
    }
}