import {BusinessInterface, BusinessModel} from "./BusinessModel";
import ClientRole from "../js/enums/ClientRole";

export interface ProfileInterface {
    id: string;
    firstName: string;
    lastName: string;
    fatherName: string;
    phone: string;
    email: string;
    emailConfirmed: boolean;
    c1Confirmed: boolean;
    business: BusinessInterface;
    created: null;
    role: ClientRole;
}

export class ProfileModel {
    id: string;
    firstName: string;
    lastName: string;
    fatherName: string;
    phone: string;
    email: string;
    emailConfirmed: boolean;
    c1Confirmed: boolean;
    business: BusinessModel;
    created: null;
    role: ClientRole;

    constructor(data: ProfileInterface) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.fatherName = data.fatherName;
        this.phone = data.phone;
        this.email = data.email;
        this.emailConfirmed = data.emailConfirmed;
        this.c1Confirmed = data.c1Confirmed;
        this.business = new BusinessModel(data.business);
        this.created = data.created;
        this.role = data.role;
    }

    static fromApi(data: any): ProfileModel {
        return new ProfileModel({
            id: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            fatherName: data.father_name,
            phone: data.phone,
            email: data.email,
            emailConfirmed: data.email_confirmed,
            c1Confirmed: data.c1_confirmed,
            business: BusinessModel.fromApi(data.business),
            created: data.created,
            role: data.role,
        });
    }
}
