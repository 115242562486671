import React from "react";
import Loader from "./Loader";
import addLocation from "../api/addLocation";
import {NotificationManager} from "react-notifications";
import simulateRequestDelay from "../util/simulateRequestDelay";
import fetchAndHandleProfile from "../util/fetchAndHandleProfile";
import {useGlobalContext} from "../util/GlobalProvider";

interface NewBusinessLocationViewProps {
    setShowModal: (show: boolean) => void;
}

const NewBusinessLocationModalView: React.FC<NewBusinessLocationViewProps> = ({...props}) => {
    const [address, setAddress] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [pending, setPending] = React.useState(false);
    const {setProfile, setLoggedIn} = useGlobalContext();

    const locationSubmit = async () => {
        setPending(true);
        try {
            await addLocation({address: address, email: email, phone: phone});
            NotificationManager.success('Адрес добавлен!', 'Успех');
            await fetchAndHandleProfile(setProfile, setLoggedIn);
            props.setShowModal(false);
        } catch {
            NotificationManager.error('Произошла ошибка при добавлении адреса!', 'Ошибка');
        } finally {
            setPending(false);
        }
    }

    return (
        <>
            <div className="common-div-form">
                <button onClick={() => props.setShowModal(false)} className="close-modal-btn">×</button>
                <h5>Новый адрес</h5>
                <form className="common-form">
                    <label className="common-form-label">Адрес</label>
                    <input
                        type="text"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        required
                        className='form-control common-form-input'
                        placeholder="Адрес"
                    />
                    <label className="common-form-label">Почта</label>
                    <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        className={'form-control common-form-input '}
                        placeholder="Почта"
                    />
                    <label className="common-form-label">Телефон</label>
                    <input
                        type="text"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        required
                        className='form-control common-form-input'
                        placeholder="Телефон"
                    />
                        {pending ? <Loader className={"loader-small"}/> : <button
                            type="submit"
                            onClick={(e) => locationSubmit()}
                            className="common-btn"
                        >
                            Добавить
                        </button>}
                </form>
            </div>
        </>
    );
}

export default NewBusinessLocationModalView;
