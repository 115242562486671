import onStartPageLoaded from "../util/onStartPageLoaded";
import React, {useEffect} from 'react';
import {NavLink, useMatch, useNavigate} from "react-router-dom";
import {
    AiOutlineUser,
    AiOutlineShopping,
    AiOutlineHome,
    AiOutlineAudit,
    AiOutlineCheck,
    AiOutlineClockCircle
} from 'react-icons/ai';
import {GoSignOut} from "react-icons/go";
import Cookies from "js-cookie";
import Config from "../util/Config";
import {useGlobalContext} from "../util/GlobalProvider";
import ActionConfirmModalView from "./ActionConfirmModalView";
import config from "../util/Config";
import ClientRole from "../enums/ClientRole";
import {Popover, Whisper} from "rsuite";

interface CustomNavLinkProps {
    children: React.ReactNode;
    to: string;
    activeClassName: string;
    inactiveClassName: string;
}

const ProfileNavigation = () => {
    const [showLogoutModal, setShowLogoutModal] = React.useState(false);
    const {profile, setLoggedIn} = useGlobalContext();
    const navigate = useNavigate();
    const CustomNavLink: React.FC<CustomNavLinkProps> = ({to, activeClassName, inactiveClassName, ...rest}) => {
        let match = useMatch(to);
        return <NavLink to={to} className={match ? activeClassName + inactiveClassName : inactiveClassName} {...rest} />
    }

    const handleLogout = () => {
        Cookies.remove('access');
        Cookies.remove('refresh');
        setLoggedIn(false);
        navigate(Config.urls.main);
    };

    useEffect(() => {
        onStartPageLoaded()
    });

    return (
        <>
            <div className="profile-navigation">
                <CustomNavLink to={Config.urls.profile} activeClassName="active-link"
                               inactiveClassName=" profile-navigation-link">
                    <AiOutlineUser className="profile-navigation-icon"/>
                    Профиль
                    {profile?.c1Confirmed &&
                        <Whisper speaker={<Popover>Ваши данные проверены. Вы можете совершать покупки!</Popover>}>
                            <div className="profile-navigation-icon right verify-status confirmed">
                                <AiOutlineCheck className="icon"/>
                                Верифицирован
                            </div>
                        </Whisper>
                    }
                    {!profile?.c1Confirmed &&
                        <Whisper speaker={<Popover>Мы проверим ваши данные в течение суток.</Popover>}>
                            <div className="profile-navigation-icon right verify-status">
                                <AiOutlineClockCircle className="icon"/>
                                На верификации
                            </div>
                        </Whisper>
                    }
                </CustomNavLink>
                <CustomNavLink to={`${Config.urls.orders}`} activeClassName="active-link"
                               inactiveClassName=" profile-navigation-link">
                    <AiOutlineShopping className="profile-navigation-icon"/>
                    Заказы
                </CustomNavLink>
                <CustomNavLink to={`${Config.urls.addresses}`} activeClassName="active-link"
                               inactiveClassName=" profile-navigation-link">
                    <AiOutlineHome className="profile-navigation-icon"/>
                    Мои адреса
                </CustomNavLink>
                {profile?.role === ClientRole.admin &&
                    <CustomNavLink to={`${Config.urls.clients}`} activeClassName="active-link"
                                   inactiveClassName=" profile-navigation-link">
                        <AiOutlineAudit className="profile-navigation-icon"/>
                        Клиенты
                        <Whisper speaker={<Popover>Видно только администраторам</Popover>}>
                            <div className="profile-navigation-icon right">
                                <img src={`${config.cdn}/icons/lock.svg`} alt="lock"/>
                            </div>
                        </Whisper>
                    </CustomNavLink>}
                <button onClick={() => {
                    setShowLogoutModal(true)
                }} className="profile-navigation-link logout"><GoSignOut
                    className="profile-navigation-icon"/>Выход
                </button>
            </div>
            <ActionConfirmModalView
                isOpen={showLogoutModal}
                setShowModal={setShowLogoutModal}
                onConfirm={handleLogout}
                confirmText="Выйти"
                cancelText="Отмена"
                message="Вы уверены, что хотите выйти из аккаунта?"
            />
        </>
    );
};

export default ProfileNavigation;
